/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
// import PhoneInput from 'react-phone-input-2';
import "../../../assets/scss/index.scss";
import "../../../assets/themes/theme1-color-variables.css";
import "../../../assets/themes/theme2-color-variables.css";
import { useInputHandlers } from "./NASDAQTotalViewNonproValidations";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const NASDAQTotalViewNonPro = ({
  onChange,
  resData,
  initialData,
  setIsValidated,
  subsData,
}: any) => {
  console.log("Web Tech Data", subsData?.customerNumber);

  const initialValues = {
    subscriberFirstName: subsData?.userInfo?.firstName
      ? subsData.userInfo.firstName
      : resData?.FirstName
      ? resData.FirstName
      : "",
    subscriberTitle: subsData?.userInfo?.jobTitle
      ? subsData.userInfo.jobTitle
      : resData?.JobTitle
      ? resData.JobTitle
      : "",
    subscriberAddress: subsData?.userInfo?.addressLine1
      ? subsData.userInfo.addressLine1
      : resData?.AddressOne
      ? resData.AddressOne
      : "",
    subscriberTelephone: subsData?.userInfo?.primaryMobile
      ? subsData.userInfo.primaryMobile
      : resData?.Mobile
      ? resData.Mobile
      : "",
    subscriberEmail: subsData?.userInfo?.email
      ? subsData.userInfo.email
      : resData?.Email
      ? resData.Email
      : "",
    subscriberCopyTo: "",
    subscriberSignature: "",
  };
  const { inputValues, errors, handleInputChange, handleBlur } =
    useInputHandlers(initialValues);
  const [userType, setUserType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [individualUserType, setIndividualUserType] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const supportEmail = "global@mubashertrade.com";
  const supportTelephone = "+97317005700";

  //Individual subscriber trigger
  const onUserTypeChange = (value: any) => {
    setUserType(value);
    if (value === "firm") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  //Non-Professional subscriber trigger
  const onIndividualUserTypeChange = (value: any) => {
    setIndividualUserType(value);
    if (value === "professional") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(inputValues).every(
      (value: any) => value.trim() !== ""
    );
    const noErrors = Object.values(errors).every((error) => error === "");

    setIsValidated(allFieldsFilled && noErrors);
  }, [inputValues, errors]);

  return (
    <div className="txt-size-xs txt-color-default p-lg">
      <div>
        <div className="row">
          <div className="display-flex jc-center mt-md">
            <img
              src="../../images/Nasdaq-logo.svg"
              alt="NASDAQ_Logo"
              width="30%"
            />
          </div>
          <div>
            <p className="display-flex txt-color-success txt-size-md txt-weight-bold jc-center mt-md">
              NASDAQ Global Subscriber Agreement
            </p>
            <p className="txt-size-md txt-weight-bold display-flex jc-center ">
              Summary
            </p>
          </div>
        </div>
      </div>

      <div className="txt-align-justify">
        Subscribers to the Information must sign NASDAQ, Inc. (“
        <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
          {" "}
          NASDAQ{" "}
        </span>
        ”) Subscriber Agreement (“<b>Agreement</b>”) or its equivalent in order
        to receive the Information (Refer to definition in{" "}
        <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
          Section 14
        </span>
        . While all terms are important, NASDAQ asks that you pay particular
        attention to the following conditions. For additional information, refer
        to the sections referenced at the end of eachcondition.
        <p className="mt-sm">
          <b>Restrictions on uses and transfers:</b> The subscriber
          (“Subscriber”) may not provide access to information described herein
          ("
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            {" "}
            Information{" "}
          </span>
          ”) or transfer this Agreement to others. The Information is only for
          use as described by the Non-Professional or Professional Subscriber
          (for U.S. Information) the Non-Professional or Professional Subscriber
          (for Canadian Information) and Business or Private (for European
          Information) definitions. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 14
          </span>
          ]
        </p>
        <p>
          <b>
            Most types of damages are excluded and remaining damages are
            limited:
          </b>{" "}
          NASDAQ is not liable for trading losses, lost profits or incidental,
          consequential or other indirect damages, even if the Information is
          untimely or incorrect. Other damages (if any) are strictly limited (in
          contract, tort or otherwise) to a capped amount. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 7
          </span>{" "}
          and{" "}
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 8
          </span>
          ]
        </p>
        <p>
          <b>No implied or statutory warranties or duties:</b> All warranties
          and duties (if any) are eliminated. There are no express warranties,
          except for a Limited Warranty regarding efforts only. Stock quotes
          might not be current and/or accurate. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 9
          </span>
          ]
        </p>
        <p>
          <b>Subscriber provides an indemnity:</b> Subscriber indemnifies NASDAQ
          and holds NASDAQ harmless for any Claims or Losses (as described in
          Section 11) resulting from Subscriber’s breach of the Agreement, from
          Subscriber’s infringement of a third-party’s intellectual property
          rights or from any third-party lawsuit related to Subscriber’s use or
          receipt of Information. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 11
          </span>
          ]
        </p>
        <p>
          <b>Notices; Notification of Changes.</b> All notices and other
          communications (except for invoices) required to be given in writing
          under this Agreement shall be directed to the signatories or, in the
          alternative, to the individuals identified in subsections (a) and (b)
          below. Notices shall be deemed to have been duly given (i) upon actual
          receipt (or date of first refusal) by the parties, or (ii) upon
          constructive receipt (or date of first refusal) if sent by certified
          mail, return receipt requested, or any other delivery method that
          actually obtains a signed delivery receipt, to the following addresses
          or to such other address as any party hereto shall hereafter specify
          by prior written notice to the other party or parties below, or (iii)
          upon posting the notice or other communication on the NASDAQTrader
          website or a successor site. If an email address is provided, NASDAQ
          may, in lieu of the above, give notice to or communicate with
          Subscriber by email addressed to the persons identified in subsection
          (a) or to such other email address or persons as Subscriber shall
          hereafter specify by prior written notice. By providing an email
          address, Subscriber agrees that any receipt received by NASDAQ from
          Subscriber’s service provider or internet computer server indicating
          that the email was received shall be deemed proof that Subscriber
          received the message. If Subscriber cannot see or printout all or any
          portion of the message, Subscriber agrees that it is Subscriber’s
          responsibility to contact NASDAQ at (301) 978–5307.
        </p>
      </div>
      <div className="col-12">
        <p className="mb-2">
          (a) If to{" "}
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Subscriber
          </span>
          :
        </p>
        <div className="display-flex col">
          <div className="w-50">
            <label>Name*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                        ${errors.subscriberFirstName ? "b-xs-shaded-4" : ""}`}
              id="subscriberFirstName"
              placeholder="First Name"
              name="subscriberFirstName"
              value={inputValues.subscriberFirstName}
              onBlur={handleBlur}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberFirstName && (
              <span style={{ color: "red" }}>{errors.subscriberFirstName}</span>
            )}
          </div>
          <div className="w-50 mlr-xl">
            <label>Title*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                errors.subscriberTitle ? "b-xs-shaded-4" : ""
              }`}
              id="subscriberTitle"
              placeholder="Title"
              name="subscriberTitle"
              value={inputValues.subscriberTitle}
              onBlur={handleBlur}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberTitle && (
              <span style={{ color: "red" }}>{errors.subscriberTitle}</span>
            )}
          </div>
        </div>
        <div className="display-flex col mt-sm">
          <div className="w-50">
            <label>Address*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                errors.subscriberAddress ? "b-xs-shaded-4" : ""
              }`}
              id="subscriberAddress"
              placeholder="Address"
              name="subscriberAddress"
              onBlur={handleBlur}
              value={inputValues.subscriberAddress}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberAddress && (
              <span style={{ color: "red" }}>{errors.subscriberAddress}</span>
            )}
          </div>
          <div className="w-50 mlr-xl">
            <label>Telephone#*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                        ${errors.subscriberTelephone ? "b-xs-shaded-4" : ""}`}
              id="telephone"
              placeholder="Telephone"
              name="subscriberTelephone"
              onBlur={handleBlur}
              value={inputValues.subscriberTelephone}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberTelephone && (
              <span style={{ color: "red" }}>{errors.subscriberTelephone}</span>
            )}
          </div>
          {/* <PhoneInput  
                        // className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                        // ${errors.subscriberTelephone ? 'b-xs-shaded-4' : ''}`} id="telephone"
                        country={ 'us'} 
                            onBlur={handleBlur}
                            inputProps={{
                                name: 'subscriberTelephone', // Ensure this matches the state key
                                placeholder: 'Telephone'
                            }}
                            value={inputValues.subscriberTelephone}
                            onChange={phone => {
                                onChange('subscriberTelephone', phone);
                                handleInputChange('subscriberTelephone', phone);
                            }} /> */}
        </div>
        <div className="display-flex col mt-sm">
          <div className="w-50">
            <label>Fax#*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2`}
              id="subscriberFax"
              placeholder="Fax"
              name="subscriberFax"
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
              }}
            />
          </div>
          <div className="w-50 mlr-xl">
            <label>Email*</label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                errors.subscriberEmail ? "b-xs-shaded-4" : ""
              }`}
              id="subscriberEmail"
              placeholder="Enter email"
              name="subscriberEmail"
              onBlur={handleBlur}
              value={inputValues.subscriberEmail}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberEmail && (
              <span style={{ color: "red" }}>{errors.subscriberEmail}</span>
            )}
          </div>
        </div>
        <div className="row mb-sm mt-sm">
          <div className="col-12 pr-xl">
            <label>
              With, in the event of notices of dispute or default, a required
              copy to:*
            </label>
            <input
              type="text"
              className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                errors.subscriberCopyTo ? "b-xs-shaded-4" : ""
              }`}
              id="subscriberCopyTo"
              name="subscriberCopyTo"
              onBlur={handleBlur}
              value={inputValues.subscriberCopyTo}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleInputChange(event.target.name, event.target.value);
              }}
            />
            {errors.subscriberCopyTo && (
              <span style={{ color: "red" }}>{errors.subscriberCopyTo}</span>
            )}
          </div>
        </div>
        <hr></hr>
        <div className="col-12">
          <p className="mb-2">
            (b) If to{" "}
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              NASDAQ
            </span>
            :
          </p>
          <p>
            NASDAQ Global Data Products <br />
            805 King Farm Boulevard <br />
            Rockville, MD 20850 <br />
            Phone: +1 301 978 5307 or +45 33 93 33 66 <br />
            <a
              className="txt-decoration-none txt-color-heading-3"
              href="mailto:DataOps@nasdaq.com"
            >
              DataOps@nasdaq.com
            </a>
            <br />
          </p>
          <p>
            With, in the event of notices of default or dispute, a required copy
            to:
          </p>
          <hr style={{ textDecoration: "underline" }}></hr>
          <p className="mb-3">
            <b className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              NASDAQ, Inc.
            </b>
            <br />
            Office of General Counsel, Attn: Contracts Group <br />
            805 King Farm Boulevard <br />
            Rockville, MD 20850 <br />
          </p>
        </div>
      </div>

      <div className="txt-align-justify">
        <p>
          <b>Governing Law; Construction:</b> Everything relating to this
          Agreement is governed by the laws as detailed in per Appendix 1. For
          Information received, this Agreement shall be deemed to have been made
          in the jurisdiction of the applicable NASDAQ Market as detailed in
          Appendix 1. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Appendix 1
          </span>
          ]
        </p>

        <p>
          <b>No oral amendments and only NASDAQ may amend:</b> The Agreement may
          not be altered orally and may only be altered by NASDAQ pursuant to an
          agreement procedure which includes notice to either the Subscriber or
          the Distributor. Failure to terminate the Agreement before, or use of
          Information thereafter, an amendment will be the Subscriber’s consent
          (or confirmation of earlier consent) to the amendment. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 13
          </span>
          ]
        </p>

        <p>
          <b>
            Distributors can impact Subscriber’s rights but not NASDAQ’s rights:
          </b>{" "}
          A Distributor does not have the authority to change the Agreement.
          Distributors are obligated to provide notice of NASDAQ changes to the
          Subscriber. However, if they do not, NASDAQ’s notice to the
          Distributor is still effective, as to Subscriber including notice of
          cancellation. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Summary
          </span>{" "}
          and
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            {" "}
            Section 13
          </span>
          ].
        </p>

        <p>
          <b>
            Requirements of Self-Regulatory Organization; Actions To Be Taken In
            Fulfillment of Statutory Obligations.
          </b>{" "}
          Subscriber acknowledges that NASDAQ may be under certain restrictions
          when offering the Information, as detailed in Appendix 1. [
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            Section 2
          </span>
          ]
        </p>

        <p>
          <b>
            Please review the following terms and conditions of the NASDAQ
            Global Subscriber Agreement before you complete the Signature
            Section: You must be 18 years of age and must designate yourself as
            either a Non-Professional or Professional and Business or Private
            Subscriber in the following section, based on the definitions
            provided in Section 12. To qualify as Non-Professional or Private
            Subscriber, you must meet all the terms set forth in Section 12.
          </b>
        </p>
        <b>
          <i>
            By completing this section, I agree to the terms and conditions set
            forth in this NASDAQ Global Subscriber Agreement.
          </i>
        </b>
      </div>
      {showWarning && (
        <div className="alert alert-warning mb-sm mt-xs">
          Customer type does not match your current profile. If you believe this
          is an error, please contact the call center for more details. [
          {supportEmail} / {supportTelephone}]
        </div>
      )}

      <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="subscriber_type"
        onChange={(event) => {
          onChange(event.target.name, event.target.value);
          onUserTypeChange(event.target.value);
        }}
      >
        <FormControlLabel
          value="individual"
          control={<Radio />}
          label={
            <span>
              <strong>Individual Subscriber</strong> – Complete Section A.
            </span>
          }
        />
        <div>
          <div
            className={`display-flex ${
              userType !== "individual" ? "form-shaded" : ""
            }`}
            style={{
              pointerEvents: userType !== "individual" ? "none" : "auto",
              opacity: userType !== "individual" ? 0.5 : 1,
            }}
          >
            <div>
              <p className="mb-md">
                <u>US or Canadian Subscriber Status:</u>
              </p>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="individual_subscriber_type"
                onChange={(event) => {
                  onIndividualUserTypeChange(event.target.value);
                }}
              >
                <FormControlLabel
                  value="professional"
                  control={<Radio />}
                  label="Professional"
                />
                <FormControlLabel
                  value="nonProfessional"
                  control={<Radio />}
                  label="Non-Professional"
                />
              </RadioGroup>
            </div>
            <div className="ml-xl">
              <p className="mb-3">
                <u>European Subscriber Status: </u>
              </p>
              <div>
                <Radio name="Business" disabled />
                <label className="ml-sm"> Business</label> <br />
              </div>
              <div>
                <Radio name="Private" disabled />
                <label className="ml-sm"> Private</label>
              </div>
            </div>
          </div>
          <div
            className={` ${
              individualUserType !== "nonProfessional" ? "form-shaded" : ""
            }`}
            style={{
              pointerEvents:
                individualUserType !== "nonProfessional" ? "none" : "auto", // Disable all inputs if not Individual
              opacity: individualUserType !== "nonProfessional" ? 0.5 : 1, // Apply shading effect
            }}
          >
            <p className="mt-md txt-size-sm">
              <b>A. Individual Subscriber Information:</b>
            </p>
            <div className="display-flex">
              <div className="mt-sm col-2">
                <p>By*:</p>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className={`p-sm txt-size-xs ml-sm w-100 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberSignature ? "b-xs-shaded-4" : ""
                  }`}
                  id="subscriberCopyTo"
                  name="subscriberSignature"
                  value={inputValues.subscriberSignature}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                <br />
                {errors.subscriberSignature && (
                  <span style={{ color: "red" }}>
                    {errors.subscriberSignature}
                  </span>
                )}
                <p>(Subscriber Signature)</p>
              </div>
            </div>
            <div className="row">
              <div className="display-flex">
                <div className="col-2">Date</div>
                <div className="col-10">: {initialData.date || ""}</div>
              </div>
            </div>
          </div>
        </div>
        <FormControlLabel
          value="firm"
          control={<Radio />}
          label={
            <span>
              <strong>Subscriber Firm or Organization</strong> – Complete
              Section B.
            </span>
          }
        />
      </RadioGroup>
      <div
        style={{
          pointerEvents: "none",
          opacity: 0.5,
        }}
      >
                <div className="display-flex">
          <div>
            <p className="mb-2">
              <u>US or Canadian Subscriber Status:</u>
            </p>
            <div>
              <Radio value="2" name="professional" />
              <label className="ml-sm">Professional</label> <br />
            </div>
          </div>
          <div className="ml-xl">
            <p className="mb-2">
              <u>European Subscriber Status: </u>
            </p>
            <div>
              <Radio value="2" name="professional" />
              <label className="ml-sm">Business</label> <br />
            </div>
          </div>
        </div>
        <div className="mt-sm">
          <p className="txt-size-sm">
            <b>B. Subscriber Firm Information</b>
          </p>
          <div className="display-flex">
            <div className="col-2">
              <p>By*:</p>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="p-sm txt-size-xs ml-sm w-100 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 "
              />
              <p>(Subscriber Signature)</p>
            </div>
          </div>
          <div className="display-flex">
            <div className="col-2">
              <p>
                Name<br></br> of : <br></br>Signator
              </p>
            </div>
            <div className="ml-sm w-100">
              <input
                type="text"
                className="p-sm txt-size-xs ml-sm w-100 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 "
              />
              <p>(Print name of person singing on behalf of subscriber firm)</p>
            </div>
          </div>

          <div className="display-flex">
            <div className="col-2">
              <p>Title:</p>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="p-sm txt-size-xs ml-sm w-100 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 "
              />
              <p>(Authorize Officer)</p>
            </div>
          </div>
          <div className="row">
            <div className="display-flex">
              <div className="col-2">Date</div>
              <div className="col-10">: {initialData.date || ""}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-md">
        <p className="txt-size-sm">
          <b>
            Distributor Information (for Distributor/Data Provider Use Only)
          </b>
        </p>

        {/* Vendor Section */}
        <div>
          <div className="display-flex">
            <div className="w-25">Distributor </div>
            <div className="w-75 border-bottom">: GTN Group Holding Ltd</div>
          </div>
          <div className="w-100 text-end font-small">
            (Print Name of Vendor Firm)
          </div>
        </div>

        {/* By Section */}
        <div className="mt-sm">
          <div className="display-flex">
            <div className="w-25">By</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          <div className="w-100 text-end font-small">
            (Print Name of the Person Signing <br /> on Behalf of the Vendor)
          </div>
        </div>

        {/* Name of the Signatory Section */}
        <div className="mt-sm">
          <div className="display-flex">
            <div className="w-25">Signatory</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          <div className="w-100 text-end font-small">
            (Signator of person signing <br /> on behalf of Distributor)
          </div>
        </div>

        {/* Title Section */}
        <div className="mt-sm">
          <div className="display-flex">
            <div className="w-25">Title</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          <div className="w-100 text-end font-small">(Authorized Officer)</div>
        </div>

        {/* Date Section */}
        <div className="mt-sm">
          <div className="display-flex">
            <div className="w-25">Date</div>
            <div className="w-75 border-bottom">: {initialData.date || ""}</div>
          </div>
        </div>
      </div>

      <div className="txt-size-sm txt-color-success mt-md">
        <b>TERMS AND CONDITIONS</b>
      </div>

      <p className="mt-sm">
        <b>
          The Distributor and its agents may not modify or waive any term of
          this Agreement. Any attempt to modify this Agreement, except by
          NASDAQ, is void.
        </b>
      </p>

      <div className="txt-align-justify">
        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            1. USE OF DATA.{" "}
          </span>
          Subscriber may not sell, lease, furnish or otherwise permit or provide
          access to the Information to any other Person or to any other office
          or place. Subscriber will not engage in the operation of any illegal
          business use or permit anyone else to use the Information, or any part
          thereof, for any illegal purpose or violate any NASDAQ or Securities
          and Exchange Commission (“SEC”) Rule or any Financial Services
          Authority Rule (“FSA”) or other applicable law, rule or regulation.
          Subscriber may not present the Information rendered in any unfair,
          misleading or discriminatory format. Subscriber shall take reasonable
          security precautions to prevent unauthorized Persons from gaining
          access to the Information.
        </p>

        <div className="mt-sm ml-sm">
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              a. Non-Professional or Private Subscriber -{" "}
            </span>{" "}
            For Non-Professional or Private Subscriber, the Information is
            licensed only for personal use. By representing to Distributor that
            Subscriber is a Non-Professional or Private Subscriber, or by
            continuing to receive the Information at a Non-Professional or
            Private Subscriber rate, Subscriber is affirming to Distributor and
            to NASDAQ that Subscriber meets the definition of Non-Professional
            or Business Subscriber as set forth in{" "}
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              Section 14
            </span>{" "}
            of this Agreement. A Non-Professional or Private Subscriber shall
            comply promptly with any reasonable request from NASDAQ for
            information regarding the Non-Professional Subscriber’s receipt,
            processing, display and redistribution of theInformation.
          </p>

          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              b. Professional or Business Subscriber -{" "}
            </span>
            For Professional or Business Subscriber, the Information is licensed
            for the internal business use and/or personal use of the
            Professional or Business Subscriber. Professional or Business
            Subscribers may, on a non-continuous basis, furnish limited amounts
            of the Information to customers in written advertisements,
            correspondence or other literature or during voice telephonic
            conversations not entailing computerized voice, automated
            information inquiry systems or similar technologies. Upon request,
            Professional or Business Subscribers shall make its premises
            available to NASDAQ for physical inspection of Distributor's Service
            and of Professional or Business Subscriber's use of the Information
            (including review of any records regarding use of or access to the
            Information and the number and locations of all devices that receive
            Information), all at reasonable times, upon reasonable notice, to
            ensure compliance with this Agreement
          </p>
        </div>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            2. PROPRIETARY DATA.
          </span>{" "}
          NASDAQ grants to Subscriber a nonexclusive, non-transferable license
          during the term of the Agreement to receive and use the Information
          transmitted to it by Distributor and thereafter, to use such
          Information as permitted under the terms of this Agreement and/or the
          NASDAQ Requirements. Subscriber acknowledges and agrees that NASDAQ
          has proprietary rights to the Information that originates on or
          derives from markets regulated or operated by NASDAQ, and compilation
          or other rights to Information gathered from other sources. Subscriber
          further acknowledges and agrees that NASDAQ's third-party information
          providers have exclusive proprietary rights to their respective
          Information. In the event of any misappropriation or misuse by
          Subscriber or anyone who accesses the Information through Subscriber,
          NASDAQ or its third-party information providers shall have the right
          to obtain injunctive relief for its respective materials. Subscriber
          will attribute source as appropriate under all the circumstances.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            3. PAYMENT
          </span>{" "}
          Subscriber shall assume full and complete responsibility for the
          payment of any taxes, charges or assessments imposed on Subscriber or
          NASDAQ (except for federal, state or local income taxes, if any,
          imposed on NASDAQ) by any foreign or domestic national, state,
          provincial or local governmental bodies, or subdivisions thereof, and
          any penalties or interest relating to the provision of the Information
          to Subscriber. Interest shall be due from the date of the invoice to
          the time that the amount(s) that are due have been paid. To the extent
          permitted by applicable law, Subscriber acknowledges and agrees that
          the termination of the Distributor's Service for failure to make
          payments shall not be considered an improper limitation of access by
          NASDAQ. For Professional or Business Subscribers, if any payment is
          due directly to NASDAQ under this Agreement, payment in full is due
          NASDAQ in immediately available funds, in the currency specified by
          NASDAQ by a check to NASDAQ, by electronic funds transfer to an
          institution of NASDAQ’s choosing or by any NASDAQ Global Subscriber
          Agreement Terms and Conditions NASDAQ Global Subscriber Agreement |
          ©Copyright 2020, Nasdaq, Inc. All Rights Reserved. Version 1.1 other
          form of payment as specified by NASDAQ in Appendix 1, within fifteen
          (15) days of the date of an invoice, whether or not use is made of, or
          access is made to, the Information.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            4. REPORTING.
          </span>{" "}
          Subscriber shall comply with all reporting requirements set forth in
          this Agreement. Except as otherwise detailed herein, Subscriber shall
          use best efforts to provide all reports within fifteen (15) days of
          the end of the applicable reporting period but no later than forty
          five (45) days from the end of the applicable reporting period. In the
          event of submission of applicable reporting pursuant to the Agreement,
          Nasdaq may Process Personal Data as part of the services or in support
          of its rights (including, but not limited to, its audit and usage
          review rights) under this Agreement. Subscriber shall provide to
          Nasdaq such Personal Data as reasonably requested by Nasdaq to perform
          the Services and enforce its rights (including, but not limited to,
          its audit and usage review rights) under this Agreement. The Parties
          agree to comply with provisions of the Data Processing
          <a
            className="txt-decoration-none txt-color-heading-3"
            href="http://www.nasdaqtrader.com/content/AdministrationSupport/AgreementsData/GDA-DPA.pdf"
          >
            &nbsp;Addendum&nbsp;
          </a>
          with respect to any Personal Data Processed by Nasdaq, each acting
          reasonably and in good faith. With respect to individuals’ whose
          Personal Data is Processed by Nasdaq, the current publicly-posted
          <a
            className="txt-decoration-none txt-color-heading-3"
            href="http://nasdaqtrader.com/Trader.aspx?id=Privacy"
          >
            &nbsp;Privacy Policy&nbsp;
          </a>
          shall apply to such Processing. Subscriber agrees to refer any
          individual whose Personal Data may be processed by Nasdaq to the
          Privacy Policy with respect to the individual’s rights with respect to
          such Processing by Nasdaq. Terms not otherwise defined in this
          Agreement shall be defined in the Data Processing Addendum.
        </p>

        <div>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            5. AUDIT.
          </span>
        </div>

        <div className="ml-md">
          <p>
            <b className="txt-color-heading-2 txt-weight-bold">a. </b> From time
            to time, and no more than once in any twelve (12) month period
            unless necessary due to suspected material non-compliance with this
            Agreement, NASDAQ, or its designee, may review Subscriber’s: (i)
            records relating to the Information; (ii) reports and payments
            relating to the Information; and (iii) System and Service (and all
            instruments and apparatus used in connection therewith). For
            avoidance of doubt, NASDAQ shall not access, examine, observe,
            review or in any way gain disclosure to any information of
            Subscriber, which is protected under applicable banking secrecy
            regulations. NASDAQ will make reasonable efforts to provide at least
            thirty (30) days advance written notice of the audit, unless the
            audit is scheduled due to suspected material non-compliance. The
            audit shall occur during NASDAQ Market normal business hours, and
            conducted in locations where, as applicable, Distributor’s records
            are kept, Subscriber’s System is located, and/or Subscriber’s uses
            the Information. NASDAQ shall comply with Subscriber’s security
            policies while performing the audit which are provided to NASDAQ in
            advance in writing.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">b. </b>Subscriber
            shall provide reasonable cooperation to NASDAQ including: (i)
            promptly providing information or materials in response to any
            reasonable request; and (ii) making available for examination all
            records, reports, payments, and supporting documentation, and
            Subscriber’s System and Subscriber’s Service necessary to reach a
            conclusion as to the accuracy and completeness of: (a) Subscriber 's
            reports to NASDAQ; (b) the payments connected therewith; (c) the
            approved System Description; and (d) compliance with thisAgreement.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">c. </b>NASDAQ
            shall endeavor to provide Subscriber with a preliminary audit
            response within ninety (90) days following the completion of any
            audit. NASDAQ shall discuss the outcome of any preliminary audit
            response in good faith with Subscriber or, at Subscriber’s election,
            with Subscriber’sdesignee.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">d. </b>After
            receipt of the preliminary audit response, Subscriber may elect, at
            Subscriber’s sole cost and expense, to conduct a subsequent review.
            This subsequent review shall be completed and submitted to NASDAQ
            within ninety (90) days after the preliminary audit response is
            received bySubscriber
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">e. </b>If NASDAQ
            and Subscriber are not able to reach agreement concerning the
            preliminary audit response nor the subsequent review within: (i)
            sixty (60) days of Subscriber’s submission or the subsequent review;
            or (ii) one hundred fifty (150) days of Subscriber’s receipt of the
            preliminary audit response, provided that NASDAQ has proceeded with
            such examination and audit in good faith in accordance with the
            provisions hereof, then NASDAQ’s determination (the “Final Audit”)
            shall be deemed conclusive.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">f. </b>f the
            Final Audit determines an underreporting, underpayment or other
            financial non-compliance with this Agreement (and/or discloses
            additional underreported or underpaid amounts or other financial
            non-compliance), then such amounts shall be remitted to NASDAQ,
            together with applicable interest within sixty (60) days of the
            completion of the Final Audit. If Subscriber fails to remit such
            additional amounts to NASDAQ by the end of this sixty (60) day cure
            period, NASDAQ may upon advance notice to Subscriber, terminate as
            provided in Section 12 of this Agreement
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">g. </b>For any
            underreporting, underpayment or other financial non-compliance that
            is a result of a good faith error by Subscriber, Subscriber’s
            liability shall be limited to unpaid fees, together with interest,
            for the three (3) years preceding the earlier of the date that: (i)
            Subscriber; (ii) Subscriber 's auditors; or (iii) NASDAQ first knew
            or determined that such underreporting, underpayment or other
            financial noncompliance has occurred, plus any costs and expenses as
            set forth in Section 5(h).
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">h. </b>If any
            underreporting, underpayment or other Subscriber financial
            non-compliance is found to be equal to or greater than ten percent
            (10%) of the reported number of Reportable Units for any audited or
            unaudited period referred to herein, , Subscriber shall, in addition
            to remitting the fees and applicable interest due relative to such
            underreorting, underpayment or other financial non-compliance,
            reimburse NASDAQ for any reasonable audit, legal or administrative
            costs and expenses incurred by NASDAQ in connection with such audit.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">i. </b>If the
            Final Audit reveals any material non-financial non-compliance with
            the NASDAQ Requirements, Subscriber shall submit all applicable
            materials demonstrating compliance with the NASDAQ Requirements,
            where reasonably practicable, within one hundred fifty (150) days
            after receipt of the preliminary audit response. If Subscriber fails
            to demonstrate compliance during this cure period, NASDAQ may, in
            its discretion at any time, with prior notice to Subscriber,
            terminate as provided in Section 12 of this Agreement
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">j. </b>If the
            Final Audit discloses an overpayment, then NASDAQ will apply the
            overpayment for the sixty(60) days prior to the end of the period
            being reviewed as a credit against amounts due from Subscriber.
          </p>

          <p>
            <b className="txt-color-heading-2 txt-weight-bold">k. </b>If
            Subscriber 's payment of fees for Information is fixed, and does not
            fluctuate with the number of Reportable Units, NASDAQ will, in lieu
            of an audit, request relevant information or documentation related
            to Subscriber's use of the Information; provided, however, that
            NASDAQ shall retain the right, with reasonable Notice to Subscriber,
            during normal business hours, to conduct an audit on a confidential
            basis of any relevant documentation of Subscriber to ensure that
            Subscriber's use of the Information conforms to the NASDAQ
            Requirements. Such audit may only take place once per calendar year
            and only if such audit is deemed reasonably necessary by NASDAQ due
            to suspected non- compliance by Subscriber with the material
            provisions of this Agreement.
          </p>
        </div>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            6. SYSTEM.
          </span>{" "}
          Subscriber acknowledges that NASDAQ, in its sole discretion, may from
          time-to-time make modifications to its system or the Information. Such
          modifications may require corresponding changes to be made in
          Distributor's Service. Changes or the failure to make timely changes
          by Distributor or Subscriber may sever or affect Subscriber's access
          to or use of the Information. NASDAQ shall not be responsible for such
          effects. NASDAQ does not endorse or approve any equipment, Distributor
          or Distributor's Service.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            7. EXCLUSIVE REMEDY.
          </span>{" "}
          NASDAQ shall endeavor to offer the Information as promptly and
          accurately as is reasonably practicable. In the event that the
          Information is not available as a result of failure by NASDAQ to
          perform its obligations under this Agreement, NASDAQ will endeavor to
          correct any such failure. If the Information is not available, is
          delayed, is interrupted, is incomplete, is not accurate or is
          otherwise materially affected for a continuous period of four (4)
          hours or more during the time that NASDAQ regularly transmits the
          Information due to the fault of NASDAQ (except for a reason permitted
          in this Agreement or in NASDAQ’s agreement with the Distributor),
          Subscriber’s or any other Person’s exclusive remedy against NASDAQ
          shall be:
        </p>

        <div className="ml-md">
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              a.
            </span>{" "}
            If Subscriber or any other Person continues to receive the
            Information or any other data and/or information offered by NASDAQ,
            a prorated month’s <u>credit</u> of any monies due, if any, for the
            affected Information directly to NASDAQ from Subscriber or, if
            applicable, from said other Person, for the period at issue; or
          </p>

          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              b.
            </span>{" "}
            If Subscriber or any other Person no longer receives either the
            Information or any other data and/or information offered by NASDAQ,
            a prorated month’s <u>refund</u> of any monies due for the affected
            Information directly to NASDAQ from Subscriber or, if applicable,
            from said other Person, for the period at issue.
          </p>
        </div>

        <p>
          Such credit or refund shall, if applicable, be requested in writing to
          NASDAQ with all pertinent details. Beyond the warranties stated in
          this section, there are no other warranties of any kind — express,
          implied, statutory (including without limitation, timeliness,
          truthfulness, sequence, completeness, accuracy, freedom from
          interruption), implied warranties arising from trade usage, course of
          dealing, course of performance or the implied warranties of
          merchantability or fitness for a particular use or purpose.
        </p>

        <p id="section8">
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            8. LIMITATION OF LIABILITY.{" "}
          </span>
        </p>

        <div className="ml-md">
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              a.
            </span>{" "}
            Except as may otherwise be set forth herein, NASDAQ shall not be
            liable to Subscriber, its Distributor or any other Person for
            indirect, special, punitive, consequential or incidental loss or
            damage (including, but not limited to, trading losses, loss of
            anticipated profits, loss by reason of shutdown in operation or
            increased expenses of operation, cost of cover or other indirect
            loss or damage) of any nature arising from any cause whatsoever,
            even if NASDAQ has been advised of the possibility of such damages.
          </p>

          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              b.
            </span>{" "}
            NASDAQ shall not be liable to Subscriber or any other Person for any
            unavailability, interruption, delay, incompleteness or inaccuracy of
            the Information that lasts less than four (4) continuous hours
            during the time that NASDAQ regularly transmits the Information or
            if the Information is materially affected for less than four (4)
            continuous hours during the time that NASDAQ regularly transmits the
            Information.
          </p>
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              c.
            </span>{" "}
            If NASDAQ is for any reason held liable to Subscriber or to any
            other Person, whether in tort or in contract, the liability of
            NASDAQ within a single year of the Agreement (one year from the
            effective date of the Agreement) is limited to an amount of
            Subscriber's damages that are actually incurred by Subscriber in
            reasonable reliance (combined with the total of all claims or losses
            of Subscriber's Distributor and any other Person claiming through,
            on behalf of or as harmed by Subscriber) and which amount does not
            exceed the lesser of:
          </p>

          <div className="ml-md">
            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                i.
              </span>{" "}
              For Subscriber or any other person that continues to receive the
              Information or any other data and/or Information offered by
              NASDAQ, a prorated month's credit of any monies due directly to
              NASDAQ from Subscriber or, if applicable, from any other Person,
              for the Information at issue during the period at issue, or if
              Subscriber or any other Person no longer receives either the
              Information or any other data and/or information offered by
              NASDAQ, a refund of any monies due directly to NASDAQ from
              Subscriber or, if applicable, from any other Person, for the
              Information at issue during the period at issue; or
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                ii.
              </span>{" "}
              $500.
            </p>
          </div>

          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              d.
            </span>{" "}
            This section shall not relieve NASDAQ, Subscriber or any other
            Person from liability for damages that result from their own gross
            negligence or willful tortious misconduct or from personal injury or
            wrongful death claims.
          </p>
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              e.
            </span>{" "}
            Subscriber and NASDAQ understand and agree that the terms of this
            section reflect a reasonable allocation of risk and limitation of
            liability.
          </p>
        </div>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            9. DISCLAIMERS OF WARRANTIES.{" "}
          </span>
          NASDAQ and its third-party information providers make no warranties of
          any kind — express, implied or statutory (including without
          limitation, timeliness, truthfulness, sequence, completeness,
          accuracy, freedom from interruption), any implied warranties arising
          from trade usage, course of dealing, course of performance or the
          implied warranties of merchantability or fitness for a particular use
          or purpose or noninfringement.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            10. THIRD-PARTY INFORMATION PROVIDERS’ LIMITATION OF LIABILITY.{" "}
          </span>
          NASDAQ’s third-party information providers shall have no liability for
          any damages for the accuracy of or for delays or omissions in any of
          the Information provided by them, whether direct or indirect, lost
          profits, special or consequential damages of the Subscriber or any
          other Person seeking relief through Subscriber, even if the
          third-party information providers have been advised of the possibility
          of such damages. In no event will the liability of the third-party
          information providers or their affiliates to Subscriber or any other
          Person seeking relief through Subscriber pursuant to any cause of
          action, whether in contract, tort or otherwise, exceed the fee paid by
          Subscriber or any other Person seeking relief through Subscriber, as
          applicable.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            11. CLAIMS AND LOSSES.{" "}
          </span>
          Subscriber will indemnify NASDAQ and hold NASDAQ and its employees,
          officers, directors and other agents harmless from any and all Claims
          or Losses imposed on, incurred by or asserted as a result of or
          relating to: (a) any noncompliance by Subscriber with the terms and
          conditions hereof; (b) any third-party actions related to Subscriber's
          receipt and use of the Information, whether authorized or unauthorized
          under the Agreement. Each party warrants and represents and will
          indemnify and hold harmless (and in every case, NASDAQ shall be
          permitted to solely defend and settle) another party (including
          NASDAQ) and their officers, directors, employees and other agents,
          against any Claims or Losses arising from, involving or relating to a
          claim of infringement or other violation of an intellectual property
          right by the indemnifying party, its actions or omissions, equipment
          or other property. This right is conditioned on the indemnified party
          giving prompt written notice to the indemnifying party (as does not
          prejudice the defense) of the Claims or Losses and providing
          cooperation in the defense of the Claims or Losses (without waiver of
          attorney-client, work product or other legal privilege, or disclosure
          of information legally required to be kept confidential)
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            12. TERMINATION.{" "}
          </span>
          Subscriber acknowledges that NASDAQ, when required to do so in
          fulfillment of statutory obligations, may by notice to Distributor
          unilaterally limit or terminate the right of any or all Persons to
          receive or use the Information and that Distributor will immediately
          comply with any such notice and will terminate or limit the furnishing
          of the Information and confirm such compliance by notice to NASDAQ.
          Any affected Person will have available to it such procedural
          protections as are provided by the Act and applicable rules there
          under
        </p>

        <p>
          In addition to terminations permitted under the Distributor's
          agreement, this Agreement may be terminated by Subscriber with thirty
          (30) days written notice to Distributor and by NASDAQ with thirty (30)
          days written notice either to Distributor or Subscriber. NASDAQ may
          also alter any term of this Agreement with ninety (90) days written
          notice either to Distributor or Subscriber, and any use after such
          date is deemed acceptance of the new terms. In the event of Subscriber
          breach, discovery of the untruth of any representation of Subscriber,
          or where directed by the SEC in its regulatory authority, NASDAQ may
          terminate this Agreement with not less than three (3) days written
          notice to Subscriber provided either by NASDAQ or Distributor.
        </p>

        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            13. AMENDMENTS/AGREEMENT.{" "}
          </span>
          Except as may otherwise be set forth in this Agreement, NASDAQ may
          alter any term or condition of this Subscriber Agreement on ninety
          (90) days notice to Distributor, and any use of the Information after
          such date is deemed acceptance of the new term or condition. The means
          of notifying Subscriber of such new term or condition may include, but
          not be limited to, emailing such term or condition to the applicable
          Distributor or posting such alteration on the NASDAQTrader website or
          a successor site shall be as per the Summary. No failure on the part
          of NASDAQ, Distributor or Subscriber to exercise, no delay in
          exercising, and no course of dealing with respect to any right, power,
          or privilege under the Agreement shall operate as a waiver thereof,
          nor shall any single or partial exercise of any such right, power, or
          privilege preclude any other or further exercise thereof or the
          exercise of any other right, power, or privilege under this Agreement.
          Except as otherwise provided herein, no provision of this Agreement
          may be amended, modified or waived. If any of the provisions of this
          Agreement or application thereof to any individual, entity or
          circumstance is held invalid or unenforceable, the remainder of this
          Agreement, or the application of such terms or provisions to
          individuals, entities or circumstances other than those as to which
          they are held invalid or unenforceable, shall not be affected thereby
          and each such term and provision of this Agreement shall be valid and
          enforceable to the fullest extent permitted by law. In the event of
          any conflict between the terms of this Agreement and of the
          Distributor's agreement, the terms of this Agreement shall prevail as
          between NASDAQ and Subscriber.
        </p>

        <div>
          <p className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            14. DEFINITIONS.{" "}
          </p>

          <div className="ml-md">
            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Act{" "}
              </span>
              shall mean the Securities Exchange Act of 1934, applicable only to
              Information disseminated from a NASDAQ Market in the United
              States.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Affiliate{" "}
              </span>
              shall mean any individual, corporation, company, partnership,
              limited partnership, limited liability company, trust, association
              or other entity that, directly or indirectly through one or more
              intermediaries, controls, is controlled by, or is under common
              control with such party.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Claims or Losses{" "}
              </span>{" "}
              — Any and all liabilities, obligations, losses, damages,
              penalties, claims, actions, suits, costs, judgments, settlements
              and expenses of whatever nature, whether incurred by or issued
              against an indemnified party or a third party, including, without
              limitation, (a) indirect, special, punitive, consequential or
              incidental loss or damage, (including, but not limited to, trading
              losses, loss of anticipated profits, loss by reason of shutdown in
              operation or increased expenses of operation or other indirect
              loss or damage), and (b) administrative costs, investigatory
              costs, litigation costs and auditors' and attorneys' fees and
              disbursements (including in-house personnel)
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Distributor{" "}
              </span>
              shall mean Distributor and its Affiliates as identified in writing
              to NASDAQ.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Distributor's Service{" "}
              </span>{" "}
              — The service from a distributor, including the data processing
              equipment, software and communications facilities related thereto,
              for receiving, processing, transmitting, using and disseminating
              the Information to or by Subscriber.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                FSA{" "}
              </span>
              shall mean a Financial Services Authority in Sweden, the United
              Kingdom, or other jurisdiction other than the United States.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Information{" "}
              </span>
              shall mean certain market data and other data disseminated that
              has been collected, validated, processed, and recorded by the
              System or other sources made available for transmission to and
              receipt from either a Distributor or from NASDAQ relating to: a)
              eligible securities or other financial instruments, markets,
              products, vehicles, indicators, or devices; b) activities of a
              NASDAQ Company; c) other information and data from a NASDAQ
              Company. Information also includes any element of Information as
              used or processed in such a way that the Information can be
              identified, recalculated or re-engineered from the processed
              Information or that the processed Information can be used as a
              substitute for Information.
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ{" "}
              </span>
              shall collectively mean NASDAQ, Inc., a Delaware limited liability
              company and its subsidiaries and Affiliates (collectively
              “NASDAQ”)
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ Markets{" "}
              </span>
              shall mean the regulated securities and options exchange
              subsidiaries of NASDAQ and other regulated market subsidiaries of
              NASDAQ, including, but not limited to , The NASDAQ Stock Market
              (“NASDAQ”), the OMX Nordic Exchange (“OMX”), NASDAQ BX (“BX”),
              NASDAQ PHLX (“PHLX”), the Philadelphia Board of Trade (“PBOT”),
              NASDAQ CXC Limited (“NASDAQ Canada”) and NASDAQ Europe. The NASDAQ
              Markets are each a{" "}
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                “NASDAQ Market.”
              </span>
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ Requirements{" "}
              </span>{" "}
              All (i) rules, regulations, interpretations, decisions, opinions,
              orders and other requirements of the SEC or an FSA, as may be
              applicable based upon the NASDAQ Market from which the Information
              is received ; (ii) the rules and regulations, disciplinary
              decision and rule interpretations applicable to NASDAQ Markets
              (iii) the NASDAQ Markets' decisions, policies, interpretations,
              operating procedures, specifications, requirements, and other
              documentation that is regulatory or technical in nature
              (including, but not limited to, user guides) published on the
              NASDAQTrader website located at{" "}
              <a
                className="txt-color-heading-2 txt-weight-bold txt-size-xs"
                href="www.NASDAQTrader.com"
              >
                www.NASDAQTrader.com
              </a>
              <br /> or another website accessible by and made known to
              Distributor; and (iv) all other applicable laws, statutes, rules,
              regulations, orders, decisions, interpretations, opinions, and
              other requirements, whether promulgated by the United States,
              England, Sweden, Canada or any other applicable jurisdiction
              (including in the area of intellectual property); and (v) the
              successors, as they may exist at the time, of the components of
              the NASDAQ
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ Trader
              </span>{" "}
              shall mean the website located at{" "}
              <a
                className="txt-color-heading-2 txt-weight-bold txt-size-xs"
                href="www.NASDAQTrader.com"
              >
                www.NASDAQTrader.com
              </a>
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Or
              </span>{" "}
              — Includes the word "and".
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Person
              </span>{" "}
              Any natural person, proprietorship, corporation, partnership or
              other entity whatsoever
            </p>

            <p>
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                Subscriber
              </span>{" "}
              — When it appears alone, the word "Subscriber" encompasses all
              Non-Professional, Private, Professional and Business Subscribers.
              All subscribers are deemed Professional or Business unless they
              are qualified as Non-Professional or Private Subscriber.
            </p>
          </div>
        </div>
      </div>

      <div className="txt-align-justify">
        <table border={1}>
          <tr className="b-sm-shaded-1 bg-color-paper-6">
            <td
              colSpan={2}
              className="txt-color-heading-2 txt-weight-bold txt-size-sm p-md"
            >
              U.S. Information
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              {" "}
              Non-Professional Subscriber
            </td>
            <td className="p-md">
              Any natural person who is{" "}
              <b>
                <u>NOT</u>
              </b>
              :
              <div className="txt-align-justify">
                <p className="mt-md">
                  (a) registered or qualified in any capacity with the SEC, the
                  Commodities Futures Trading Commission, any state securities
                  agency, any securities exchange or association or any
                  commodities or futures contract market or association
                </p>

                <p>
                  (b) engaged as an "investment advisor" as that term is defined
                  in{" "}
                  <a
                    className="text1"
                    href="https://www.investor.gov/introduction-investing/investing-basics/role-sec/laws-govern-securities-industry#invadvact1940"
                  >
                    Section 202(a)(11) of the Investment Advisors Act of 1940
                  </a>
                  (whether or not registered or qualified under that Act); or
                </p>

                <p>
                  (c) employed by a bank or other organization exempt from
                  registration under federal or state securities laws to perform
                  functions that would require registration or qualification if
                  such functions were performed for an organization not soexempt
                </p>
              </div>
            </td>
          </tr>
          <tr className="b-sm-shaded-1 bg-color-paper-7">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              Professional Subscriber
            </td>
            <td className="p-md">
              All other persons who do{" "}
              <b>
                <u>not</u>
              </b>{" "}
              meet the definition of Non-Professional Subscriber.
            </td>
          </tr>
          <tr className="b-sm-shaded-1 bg-color-paper-6">
            <td
              colSpan={2}
              className="txt-color-heading-2 txt-weight-bold txt-size-sm p-md"
            >
              Canadian Information
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              Non-Professional Subscriber
            </td>
            <td className="p-md txt-align-justify b-sm-shaded-1">
              Non-Professional is defined as a natural person who is not a
              securities professional and who is acting in his/her personal
              capacity and not as a principal, officer, partner, employee nor
              agent of any business, nor on behalf of any individual. To provide
              further clarification but without being limited, the definition
              excludes brokers, dealers, investment advisors, or persons
              otherwise employed by organizations conducting professional
              activities involving the buying and selling of instruments, such
              as stocks, bonds, options, futures contracts and other trading
              vehicles. A Non-Professional cannot be registered or qualified
              with:
              <div className="mt-md">
                <p>
                  a. Any provincial, state, or other government securities
                  agency or other entity that performs functions that are
                  equivalent to those of such persons;
                </p>

                <p>
                  b. Any securities exchange or association or other entity that
                  performs functions that are equivalent to those of such
                  persons;
                </p>

                <p>
                  c. Any commodities or futures exchange or association or other
                  entity that performs functions that are equivalent to those of
                  such persons; and
                </p>

                <p>
                  d. Any Alternative Trading System or other entity that
                  performs functions that are equivalent to those of such
                  persons.
                </p>
              </div>
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              {" "}
              Professional Subscriber
            </td>
            <td className="p-md txt-align-justify b-sm-shaded-1">
              A User that does not meet the definition of a Non Professional
              below shall be deemed to be a Professional and is subject to the
              fees specified for Professionals.
            </td>
          </tr>
          <tr className="bg-color-paper-6">
            <td
              colSpan={2}
              className="p-md txt-color-heading-2 txt-weight-bold txt-size-sm txt-align-justify b-sm-shaded-1"
            >
              {" "}
              European Information
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              Private Subscriber
            </td>
            <td className="p-md txt-align-justify b-sm-shaded-1">
              A natural person for the purpose of managing the Subscriber’s own
              personal investments and not for any business purpose, nor for the
              purpose of giving any form of advice to any other person. A
              Private Use Subscriber may <u>not</u>:
              <div className="mt-md">
                <p>
                  (a) contract for, receive or use Information for the purpose
                  of Private Use on behalf of any other person or any
                  corporation, partnership, limited liability company, trust,
                  association or other form of entity,
                </p>

                <p>
                  (b) contract for, receive or use Information for the purpose
                  of Private Use in any Service that is paid for by another
                  person or any corporation, partnership, limited liability
                  company, trust, association or other form of entity.
                  <br />A Private Use Subscriber <u>shall</u>, notwithstanding
                  the above:
                </p>

                <p>
                  (c) be permitted to contract for, receive or use Information
                  on behalf of or paid for by another natural person (person B)
                  provided that (1) its for the purpose of managing person B’s
                  own personal investments and not for any business purpose, and
                  (2) person B have filed a power of attorney or equivalent
                  documentation accordingly with Licensee,
                </p>

                <p>
                  (d) be permitted to contract for, receive or use Information
                  for Private Use on behalf of and/or paid for by a legal entity
                  or other form of non-natural Person in which the Private Use
                  Subscriber has full (100%) ownership and exercises full
                  (100%)control,
                </p>

                <p>(e) Section (c) and (d) may not be combined</p>
              </div>
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="p-md txt-color-heading-2 txt-weight-bold txt-align-justify b-sm-shaded-1">
              Business Subscriber
            </td>
            <td className="p-md txt-align-justify b-sm-shaded-1">
              All other persons who do{" "}
              <b>
                <u>not</u>
              </b>{" "}
              meet the definition of Private Subscriber.
            </td>
          </tr>
        </table>
        <p className="mt-md">
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            {" "}
            “System”
          </span>{" "}
          shall mean any system NASDAQ has developed for the creation and/or
          dissemination of Information.
        </p>
      </div>

      <div className="txt-color-success ml-md txt-size-sm">Appendix 1 </div>

      <div className="mt-md">
        <p>
          <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
            1. INFORMATION.
          </span>{" "}
          NASDAQ offers Information that has been collected, validated,
          processed, and recorded by the System or other sources. For data
          offered from other sources, the governing laws shall apply as
          identified below.
        </p>
        <table className="mt-md">
          <tr>
            <th className="b-sm-shaded-1 p-md txt-align-justify">
              Legal Entity and Principal Place of Busines
            </th>
            <th className="b-sm-shaded-1 p-md txt-align-justify">
              Entity Type
            </th>
            <th className="b-sm-shaded-1 p-md txt-align-justify">Funds</th>
            <th className="b-sm-shaded-1 p-md txt-align-justify">
              Governing Laws
            </th>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ, Inc.
              </p>
              <p>One Liberty Plaza 165Broadway, NewYork, NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ CXC Limited
              </span>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Canadian Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              CAD Dollars
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              The Agreement shall be governed by the laws of the Province of
              Ontario and the laws of Canada applicable therein without
              regarding to its choice of laws provisions. It is the express wish
              of the parties that this Agreement and all related documents,
              including notices and other communications, be drawn up in the
              English language only. Il est la volonté expresse des parties que
              cette convention et tous les documents s’y rattachant, y compris
              les avis et les autres communications, soient rédigés et signés en
              anglais seulement.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ OMX Information LLC
              </p>
              <p>One Liberty Plaza 165Broadway, NewYork, NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ OMX Nordic Oy
              </p>
              <p>Fabianinkatu 14, FI-00131 Helsinki FINLAND</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Finnish company
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">Euros</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be governed by and construed in accordance
              with the laws of Finland. Any dispute thatcannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules
              for expedited arbitrations of the Arbitration Institute of the
              Stockholm Chamber of Commerce. All such proceedings shall be held
              in Stockholm, and shall be conducted in the English language,
              which shall also be the language of the documents.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">NASDAQ BX</p>
              <p>One Liberty Plaza 165Broadway, NewYork, NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold txt-size-xs">
                NASDAQ PHLX
              </p>
              <p>1900 Market Street Philadelphia, PA 19103 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ Events Subsidiary
              </p>
              <p>One Liberty Plaza 165 Broadway New York, NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ ISE, LLC
              </p>
              <p>One Liberty Plaza 165 Broadway NewYork,NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ MRX, LLC
              </p>
              <p>One Liberty Plaza 165 Broadway NewYork,NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ GEMX, LLC
              </p>
              <p>One Liberty Plaza 165 Broadway NewYork,NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ Futures Exchange
              </p>
              <p>1900 Market Street Philadelphia, PA 19103 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td>US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ Commodities AS
              </p>
              <p>Vollsveien 19 PO Box 373 NO-1326 Lysaker NORWAY</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Norwegian Company
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">Euros</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be governed by and construed in accordance
              with the laws of Norway. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the
              Norwegian Arbitration Act 2004. Unless the parties agree otherwise
              such proceedings shall be held in Oslo, and shall be conducted in
              the English language, which shall also be the language of the
              documents. The proceedings as well as the verdict shall be
              confidential between the parties, except for information which a
              party is required to disclose by virtue of law or governmental
              order.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ Europe Limited
              </p>
              <p>131 Finsbury Pavement, London EC2A 1NT UNITED KINGDOM</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              British Company
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              British Pounds
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by
              English law, without reference to principles of conflicts of laws
              thereof. Any dispute that cannot be amicably settled that arises
              out of this Agreement shall be referred to arbitration and shall
              be conducted pursuant to the Rules of Arbitration of the London
              Court of International Arbitration from time to time in force. All
              such proceedings shall be held in London, and shall be conducted
              in the English language, which shall also be the language of the
              documents
            </td>
          </tr>
          <tr className="bg-color-paper-7">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ NLX Limited
              </p>
              <p>131 Finsbury Pavement, London EC2A 1NT UNITED KINGDO</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              British Company
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              British Pounds
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by
              English law, without reference to principles of conflicts of laws
              thereof. Any dispute that cannot be amicably settled that arises
              out of this Agreement shall be referred to arbitration and shall
              be conducted pursuant to the Rules of Arbitration of the London
              Court of International Arbitration from time to time in force. All
              such proceedings shall be held in London, and shall be conducted
              in the English language, which shall also be the language of the
              documents.
            </td>
          </tr>
          <tr className="bg-color-paper-8">
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              <p className="txt-color-heading-2 txt-weight-bold">
                NASDAQ eSpeed/Kleos
              </p>
              <p>One Liberty Plaza 165 Broadway NewYork,NY10006 USA</p>
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              Delaware Corporation
            </td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">US Dollars</td>
            <td className="b-sm-shaded-1 p-md txt-align-justify">
              This Agreement shall be construed and enforced in accordance with,
              and the validity and performance hereof shall be governed by, the
              laws of the State of New York, without reference to principles of
              conflicts of laws thereof. Any dispute that cannot be amicably
              settled that arises out of this Agreement shall be referred to
              arbitration and shall be conducted in accordance with the rules of
              the American Arbitration Association. All such proceedings shall
              be held in New York City, NY, and shall be conducted in the
              English language, which shall also be the language of the
              documents.
            </td>
          </tr>
        </table>
        <p className="txt-color-heading-2 txt-weight-bold txt-size-xs mt-md">
          2. REQUIREMENTS OF SELF-REGULATORY ORGANIZATION; ACTIONS TO BE TAKEN
          IN FULFILLMENT OF STATUTORY OBLIGATIONS.
        </p>
        <div className="ml-md txt-align-justify">
          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              a.
            </span>{" "}
            Subscriber acknowledges that in the United States: (i) several of
            the NASDAQ Markets are registered with the SEC as national
            securities exchanges pursuant to Section 6 of the Act, and FINRA is
            registered with the SEC as a national securities association
            pursuant to 15A of the Act; (ii) FINRA and NASDAQ have a statutory
            obligation to protect investors and the public interest, and to
            ensure that quotation information supplied to investors and the
            public is fair and informative, and not discriminatory, fictitious
            or misleading; (iii) Section 19(g)(1) of the Act mandates that FINRA
            and NASDAQ comply with the NASDAQ Requirements; (iv) NASDAQ has
            jurisdiction to enforce compliance with certain of the NASDAQ
            Requirements; (v) FINRA has jurisdiction to enforce compliance with
            certain of the NASDAQ Requirements; and (vi) NASDAQ is obligated to
            offer terms that are not unreasonably discriminatory between
            Subscribers, subject to applicable NASDAQ Requirements. Accordingly,
            Subscriber agrees that NASDAQ, when required to do so in fulfillment
            of its statutory obligations, may, temporarily or permanently,
            unilaterally condition, modify or terminate the right of any or all
            individuals or entities to receive or use the Information. NASDAQ
            shall undertake reasonable efforts to notify Subscriber of any such
            condition, modification or termination, and Subscriber shall
            promptly comply with any such notice within such period of time as
            may be determined in good faith by NASDAQ to be necessary,
            consistent with its statutory obligations. Any Person that receives
            such a notice shall have available to it such procedural protections
            as are provided to it by the Act and the applicable rules
            thereunder.
          </p>

          <p>
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              b.
            </span>{" "}
            Subscriber acknowledges that, in Europe, NASDAQ is obligated to
            offer terms that are not unreasonably discriminatory between
            Subscribers, subject to applicable NASDAQ Requirements. NASDAQ when
            required to do so in fulfillment of the its statutory obligations,
            may, temporarily or permanently, unilaterally condition, modify or
            terminate the right of any and all individuals or entities to
            receive or use the Information. NASDAQ shall notify the Subscriber
            of any such condition, modification or termination, and the
            Subscriber shall promptly comply with any such notice within such
            period of time as may be determined in good faith by NASDAQ to be
            necessary, consistent with its statutory obligations. If the
            Subscriber receives such notice, it will have available to it all
            procedural protections provided to it by statute and any applicable
            regulatory rules.
          </p>

          <p>
            {" "}
            <span className="txt-color-heading-2 txt-weight-bold txt-size-xs">
              c.
            </span>{" "}
            If Subscriber is a member of a NASDAQ Market, then Subscriber
            expressly acknowledges and agrees that (i) this Agreement does not
            limit or reduce in any way Subscriber’s obligations and
            responsibilities as a member of any applicable NASDAQ Market; (ii)
            this Agreement does not in any way alter the procedures or standards
            generally applicable to disciplinary or other actions taken by
            NASDAQ to enforce compliance with, or impose sanctions for
            violations of, the NASDAQ Requirements; and (iii) the nonpayment of
            amounts due under this Agreement could result in the suspension or
            cancellation of Subscriber's membership in a NASDAQ Market in
            accordance with the NASDAQ Requirements.
          </p>
        </div>
      </div>
    </div>
  );
};
export default NASDAQTotalViewNonPro;
