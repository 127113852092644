/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
 */

import { useEffect, useState } from "react";
import "../../../assets/scss/index.scss";
import "../../../assets/themes/theme1-color-variables.css";
import "../../../assets/themes/theme2-color-variables.css";
import { useInputHandlers } from "./GeneralAgreementValidation";

const GeneralAgreement = ({
  onChange,
  initialData,
  resData,
  setIsValidated,
  subsData,
}: any) => {
  console.log("Web Tech Data", subsData?.customerNumber);

  const initialValues = {
    subscriber_userID: subsData?.customerNumber
      ? subsData.customerNumber
      : resData?.OMS_UserID
      ? resData.OMS_UserID
      : "",
    subscriberSignature: "",
    subscriberName:
      subsData?.userInfo?.firstName && subsData?.userInfo?.lastName
        ? `${subsData.userInfo.firstName} ${subsData.userInfo.lastName}`
        : resData?.FirstName && resData?.LastName
        ? `${resData.FirstName} ${resData.LastName}`
        : "",
    subscriberAddress: subsData?.userInfo?.addressLine1
      ? subsData.userInfo.addressLine1
      : resData?.AddressOne
      ? resData.AddressOne
      : "",
    subscriberCity: subsData?.userInfo?.city
      ? subsData.userInfo.city
      : resData?.City
      ? resData.City
      : "",
    subscriberCountry: subsData?.userInfo?.country
      ? subsData.userInfo.country
      : resData?.Country
      ? resData.Country
      : "",
    subscriberEmail: subsData?.userInfo?.email
      ? subsData.userInfo.email
      : resData?.Email
      ? resData.Email
      : "",
    subscriberInternalReference: "",
  };

  const { inputValues, errors, handleInputChange, handleBlur } =
    useInputHandlers(initialValues);

  useEffect(() => {
    const allFieldsFilled = Object.values(inputValues).every(
      (value: any) => value.trim() !== ""
    );
    const noErrors = Object.values(errors).every((error) => error === "");

    setIsValidated(allFieldsFilled && noErrors);
  }, [inputValues, errors]);

  return (
    <div className="txt-size-xs txt-color-default p-lg">
      <div className="">
        <div className="display-flex jc-center">
          <img
            src="https://dev-esm.gtn.tech/app-container/15934ffc2f6078ac7274.svg"
            alt="GTN Logo"
            width="10%"
          />
        </div>
        <div className="display-flex jc-center mt-md txt-size-md txt-weight-bold txt-align-center">
          EXCHANGE MARKET DATA SUBSCRIBER AGREEMENT
        </div>
      </div>
      <div className="mt-md txt-align-justify">
        This Market Data Subscriber Agreement (the “Agreement”) is entered into
        by and between the Client/ End User (“Subscriber”) and GTN GROUP HOLDING
        LIMITED or its relevant Affiliate (“VENDOR”). This Agreement governs the
        Subscriber’s receipt and use of the Market Data and constitutes a
        binding legal agreement by and between VENDOR and the Subscriber
        (collectively the “Parties” and individually “a Party”).
      </div>
      <div>
        <div>
          <div className="txt-color-body-shaded-3 mt-sm txt-weight-bold txt-size-sm">
            TERMS AND CONDITIONS
          </div>
          <div>
            <div>
              <div className="mt-sm txt-align-justify">
                <b className="txt-color-heading-2">1. USE OF DATA. </b>
                Subscriber may not sell, lease, furnish or otherwise permit or
                provide access to Market Data to any other Person or to any
                other office or place. Subscriber will not engage in the
                operation of any illegal business use or permit anyone else to
                use Market Data, or any part thereof, for any illegal purpose or
                violate any Exchange/s Rule or other applicable law, rule or
                regulation. Subscriber may not present Market Data rendered in
                any unfair, misleading or discriminatory format. Subscriber
                shall take reasonable security precautions to prevent
                unauthorized Persons from gaining access to Market Data.
              </div>
              <div>
                <div className="pl-md mt-sm txt-align-justify ">
                  <b className="txt-color-heading-2">
                    a. NON-PROFESSIONAL SUBSCRIBER -{" "}
                  </b>
                  For Non-Professional Subscribers, Market Data is licensed only
                  for personal use. By representing to VENDOR that Subscriber is
                  a Non-Professional Subscriber, Subscriber is affirming to
                  VENDOR and to Exchange/s that Subscriber meets the definition
                  of Non-Professional Subscriber as set forth in Section 16 (h)
                  of this Agreement. A Non-Professional Subscriber shall comply
                  promptly with any reasonable request from Exchange/s for
                  information regarding the Non-Professional Subscriber’s
                  receipt, processing, display and redistribution of Market
                  Data.
                </div>
                <div className="pl-md mt-sm txt-align-justify ">
                  <b className="txt-color-heading-2">
                    b. PROFESSIONAL SUBSCRIBER -{" "}
                  </b>
                  For Professional Subscribers, Market Data is licensed for the
                  internal business use and/or personal use of the Professional
                  Subscriber. Professional Subscribers may, on a non-continuous
                  basis, furnish limited amounts of Market Data to customers in
                  written advertisements, correspondence or other literature or
                  during voice telephonic conversations not entailing
                  computerized voice, automated information inquiry systems or
                  similar technologies subject to Exchange’s terms and
                  conditions. Upon request, Professional Subscribers shall make
                  its premises available to Exchange/s for physical inspection
                  of VENDOR's Service and of Professional Subscriber's use of
                  Market Data (including review of any records regarding use of
                  or access to Market Data and the number and locations of all
                  devices that receive Information), all at reasonable times,
                  upon reasonable notice, to ensure compliance with this
                  Agreement.
                </div>
              </div>
              <div className="mt-sm txt-align-justify">
                <b className="txt-color-heading-2">
                  2. PROPRIETARY RIGHTS IN THE MARKET DATA AND THE SYSTEM.{" "}
                </b>
                VENDOR grants to Subscriber a nonexclusive, non-transferable
                license during the term of the Agreement to receive and use the
                Information transmitted to it by VENDOR and thereafter to use
                such Market Data as permitted under the terms of this Agreement.
                Subscriber acknowledges and agrees that Exchange/s has
                proprietary rights to Market Data that originates on or derives
                from markets regulated or operated by the respective Exchange/s,
                and compilation or other rights to information gathered from
                other sources. Subscriber acknowledges and agrees that any
                Market Data which is not within the public domain, constitute
                valuable confidential information, trade secrets and/or
                proprietary rights of each of the Exchanges, that such Market
                Data shall remain valuable confidential information, trade
                secrets and/or proprietary rights of each of the Exchanges at
                least until the Exchanges place their respective Market Data in
                the public domain or authorize placement of their respective
                Market Data in the public domain. Subscriber further
                acknowledges and agrees that VENDOR's third-party information
                providers have exclusive proprietary rights to their respective
                information. In the event of any misappropriation or misuse by
                Subscriber or anyone who accesses Market Data through
                Subscriber, VENDOR, its third-party information providers, or
                Exchanges shall have the right to obtain injunctive relief.
                Subscriber will attribute source as appropriate under all the
                circumstances. Parties acknowledge and agree that VENDOR and/or
                its Affiliates own/s the Intellectual Property Rights in the
                System and any software provided for Subscriber’s use. Except as
                expressly stated herein, this Agreement does not grant to
                Subscriber any rights to, or in, patents, copyrights, database
                rights, trade secrets, trade names, trademarks (whether
                registered or unregistered), or any other rights or licences in
                respect of the Services or any related documentation. Except as
                expressly provided herein, nothing herein contained shall be
                construed so as to transfer any Intellectual Property Rights
                whatsoever to the Subscriber.
              </div>
              <div className="mt-sm txt-align-justify">
                <b className="txt-color-heading-2">3. PAYMENT. </b> Subscriber
                shall assume full and complete responsibility for the payment of
                any taxes, charges or assessments imposed on Subscriber or
                VENDOR (except for federal, state or local income taxes, if any,
                imposed on VENDOR) by any foreign or domestic national, state,
                provincial or local governmental bodies, or subdivisions
                thereof, and any penalties or interest relating to the provision
                of Market Data to Subscriber. Interest shall be due from the
                date of the invoice to the time that the amount(s) that are due
                have been paid. To the extent permitted by applicable law,
                Subscriber acknowledges and agrees that the termination of the
                VENDOR's Service for failure to make payments shall not be
                considered an improper limitation of access by Exchange/s. For
                Professional Subscribers, if any payment is due directly to
                Exchange/s under this Agreement, payment in full is due
                Exchange/s.
              </div>
              <div className="mt-sm txt-align-justify">
                <b className="txt-color-heading-2">4. SYSTEM. </b>
                Subscriber acknowledges that VENDOR, in their sole discretion or
                at the request of the Exchange/s may from time-to-time make
                modifications to its System or dissemination of Market Data.
                Such modifications may require corresponding changes to be made
                in VENDOR’s Service. Changes or the failure to make timely
                changes by Subscriber may affect Subscriber's access to or use
                of Market Data. VENDOR and/or the Exchange/s shall not be
                responsible for any losses incurred by Subscriber due to such
                modifications.
              </div>
              <div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    5. ACKNOWLEDGEMENTS AND UNDERTAKINGS.{" "}
                  </b>
                </div>
                <div>
                  <div>
                    <div className="pl-sm mt-sm txt-color-heading-2">
                      Non-Professional Subscriber
                    </div>
                    <div className="pl-md">
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">a&#41;</b>{" "}
                        Non-professional Subscriber agrees to use Market Data
                        only for Subscriber’s own personal use, and not for any
                        business purpose.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">b&#41;</b>{" "}
                        Non-professional Subscriber agrees not to transfer or
                        publish to third parties any item of Market Data.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">c&#41; </b>
                        Non-professional Subscriber Recognizes the Intellectual
                        Property Rights and right to legal remedy of VENDOR
                        and/or the Exchanges/s in respect of information.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">d&#41;</b>{" "}
                        Non-professional Subscriber agrees that VENDOR,
                        Exchange/s and Information Providers accept no liability
                        for the display of Market Data or any losses or claims
                        arising from use of Market Data
                      </div>
                      <div className="mt-sm">
                        <b className="txt-color-heading-2">e&#41;</b>{" "}
                        Non-professional Subscriber agrees:
                        <div className="mt-sm ml-md">
                          <div>
                            <b className="txt-color-heading-2">i.</b> Not to
                            allow any other Persons to gain unauthorized access
                            to Market Data,
                          </div>
                          <div className="mt-sm">
                            <b className="txt-color-heading-2"> ii.</b> Not to
                            make Subscriber’s User ID(s) available to another
                            Person,
                          </div>
                          <div className="mt-sm">
                            <b className="txt-color-heading-2">iii.</b> Not to
                            use Information for any illegal purpose,
                          </div>
                          <div className="mt-sm">
                            <b className="txt-color-heading-2 txt-align-justify">
                              iv.
                            </b>{" "}
                            To obtain and provide any consents needed for the
                            respective subscribed exchange(s) or its authorized
                            representatives to review and receive personal data,
                            where necessary for the purposes of verifying or
                            ensuring compliance with VENDOR’s obligations to the
                            respective subscribed exchange(s).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="pl-sm mt-sm txt-color-heading-2">
                      Professional Subscriber
                    </div>
                    <div className="mt-sm pl-md">
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">a&#41;</b>
                        No Subscriber shall, without the prior written approval
                        of Exchange/s, further disseminate Market Data or any
                        part thereof to any other Person except Subscriber may
                        distribute limited extracts of Market Data provided this
                        is done infrequently in a non-systematic manner.
                        However, Exchange/s should have sole and absolute
                        discretion to define “limited”, “infrequent” and
                        “non-systematic” in this condition.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">b&#41;</b>
                        No Subscriber shall use or permit the use of Market Data
                        or any part thereof for any illegal purpose.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">c&#41;</b>
                        No Subscriber shall use Market Data or any part thereof
                        other than in the ordinary course of its own business
                        (which shall not include dissemination to third parties)
                        except Subscriber may distribute limited extracts of
                        Market Data provided this is done infrequently in a
                        non-systematic manner. However, Exchange/s should have
                        sole and absolute discretion to define “limited”,
                        “infrequent” and “non-systematic” in this condition.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">d&#41;</b>
                        No Subscriber shall use Market Data or any part thereof
                        to establish, maintain or provide or to assist in
                        establishing, maintaining or providing an Off Market.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">e&#41;</b>
                        No Subscriber shall decipher Market Data or use Market
                        Data, without prior written consent of Exchange/s, for
                        (i) calculation of indices of stocks, securities,
                        commodities, markets, or for (ii) development, issuance,
                        creation or sponsorship of any financial instruments or
                        investment products (including, without limitation,
                        derivatives, structured products, investment funds or
                        exchange-traded funds) where the price, return and/or
                        performance of such instrument or product is based on,
                        related to, or intended to track, any element of Market
                        Data or a proxy for such element.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">f&#41;</b>
                        VENDOR shall be able to terminate the Subscriber’s
                        contract at Exchange/s direction at any time should
                        Subscriber be in breach of any of the provisions of such
                        contract and has failed to rectify such breach within 30
                        days of receipt of written notice of the breach.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">g&#41;</b>
                        Subscriber shall maintain a log of all its end users and
                        shall from time to time upon Exchange/s request report
                        to Exchange/s or if applicable to the VENDOR the number
                        and identities of its end users, their locations, and
                        the models and number of devices used by such end users
                        and any other information as Exchange/s may reasonably
                        request.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">h&#41;</b>
                        If applicable, Subscriber shall use commercially
                        reasonable efforts to administer its user control system
                        and its use and access to the VENDOR’s Service and
                        notify VENDOR of the name and contact details of the
                        administrator as soon as practicable after execution of
                        its contract with the Exchange/s.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">i&#41;</b>
                        Subscriber shall not provide access to Market Data to
                        any end user who is not an employee of Subscriber.
                        Subscriber shall ensure that Market Data is used only by
                        its end users pursuant to the terms and conditions of
                        its contract with VENDOR using the unique password and
                        secure identification device assigned to that end user.
                        Subscriber shall ensure that there are effective
                        controls to prevent simultaneous login by the same end
                        user account on different Subscriber devices and sharing
                        of Subscriber devices, passwords and secure
                        identification devices by individual end users. Each
                        Subscriber device may only be used by one designated end
                        user.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">j&#41;</b>
                        Subscriber acknowledges that Market Data provided on
                        real-time basis remains the valuable property of the
                        Exchange/s/ or providers of such Market Data, and that
                        it is a condition of the supply of such Market Data to
                        the Subscriber that it will only use such Market Data on
                        the basis set out in this Agreement, and no other.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">k&#41;</b>
                        If applicable, each Subscriber shall at all reasonable
                        times permit access to VENDOR, Exchange/s and/ or their
                        employees or appointed agents to the premises where the
                        Subscriber devices for accessing Market Data are located
                        for the purposes of inspecting its user control system,
                        auditing the Subscriber’s log of end users and verifying
                        that the Subscriber is using Market Data in accordance
                        with the provisions of Subscriber’s contract Exchange/s
                        and this Agreement. If an audit of a Subscriber reveals
                        any underpayment of Fees due to Exchange/s in respect of
                        that Subscriber’s access to Market Data that exceeds 5%
                        of the total paid to Exchange/s for such access during
                        the period covered by the audit, where requested by
                        Exchange/s the Subscriber shall pay the reasonable costs
                        and expenses of the audit, including the cost of
                        resolving any uncertainty or disagreement as to the
                        amount due to Exchange/s as a result of the audit.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">l&#41;</b>
                        No Subscriber shall, without the prior written approval
                        of Exchange/s disseminates Market Data or any part
                        thereof to any other Person.
                      </div>
                      <div className="mt-sm txt-align-justify">
                        <b className="txt-color-heading-2">m&#41;</b>
                        Exchange/s and Market Data providers accept no liability
                        for the display of Market Data or any losses or claims
                        arising from use of the Market Data
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    6. DISCLAIMER OF WARRANTIES.{" "}
                  </b>
                  Subscriber agrees that the Market Data is provided, on an “as
                  is” and/or “as available” basis and VENDOR, Exchange/s and
                  their third-party information providers make no warranties of
                  any kind — express, implied or statutory (including without
                  limitation, timeliness, truthfulness, sequence, completeness,
                  accuracy, freedom from interruption), any implied warranties
                  arising from trade usage, course of dealing, course of
                  performance or the implied warranties of merchantability or
                  fitness for a particular use or purpose or noninfringement.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    7. LIMITATION OF LIABILITY.{" "}
                  </b>
                  VENDOR, VENDOR’s third-party Market Data providers including
                  but not limited to the Exchanges shall have no liability for
                  any damages for the accuracy or for delays or omissions in any
                  of Market Data provided by the Exchanges or the third-party
                  Market Data providers , whether direct or indirect, lost
                  profits, special or consequential damages of the Subscriber or
                  any other Person seeking relief through Subscriber, even if
                  VENDOR, Exchanges or third-party Market Data providers have
                  been advised of the possibility of such damages. In no event
                  will the liability of VENDOR, or its Affiliates to Subscriber
                  or any other Person seeking relief through Subscriber under
                  this Agreement pursuant to any cause of action, whether in
                  contract, tort or otherwise, exceed the fees paid by
                  Subscriber to the VENDOR during the three months preceding the
                  date of such claim.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">8. CLAIMS AND LOSSES. </b>
                  Subscriber will indemnify VENDOR and the Exchange/s and hold
                  VENDOR and Exchange/sand their employees, officers, directors
                  and other agents harmless from any and all Claims or Losses
                  imposed on, incurred by or asserted as a result of or relating
                  to: (a) any noncompliance by Subscriber with the terms and
                  conditions hereof; (b) any third-party actions related to
                  Subscriber's receipt and use of Market Data, whether
                  authorized or unauthorized under the Agreement.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">9. PERSONAL DATA. </b>{" "}
                  Subscriber acknowledges that VENDOR, in the course of
                  providing services to Subscriber, may process Personal Data in
                  the performance of services or in support of its rights
                  (including, but not limited to, its audit and usage review
                  rights) under this Agreement. Subscriber shall provide to
                  VENDOR or its designee such Personal Data (including, but not
                  limited to, information regarding Subscriber or, for
                  Subscribers that are companies, information regarding
                  individual users of Market Data) as reasonably requested by
                  Exchange/s to make Market Data available to Subscriber,
                  perform services under the Vendor Agreement, and/or enforce
                  Exchange/s rights (including, but not limited to, its audit
                  and usage review rights) under the Vendor Agreement..
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    10. TERM AND TERMINATION.{" "}
                  </b>
                  Upon signing this Agreement by Subscriber, Subscriber
                  acknowledges and agrees that this Agreement will immediately
                  become effective until the termination of this Agreement.
                  Subscriber acknowledges that Exchange/s, when required to do
                  so in fulfillment of statutory obligations, may by notice to
                  VENDOR unilaterally limit or terminate the right of any or all
                  Persons to receive or use Market Data and that VENDOR will
                  immediately comply with any such notice and will terminate or
                  limit the furnishing of Market Data and confirm such
                  compliance by notice to Exchange/s. In addition to
                  terminations permitted under the Client agreements, this
                  Agreement may be terminated by either Party with thirty (30)
                  days written notice to the other Party. VENDOR may also alter
                  any term of this Agreement with ninety (90) days written
                  notice to Subscriber, and any use after such date is deemed
                  acceptance of the new terms. In the event of Subscriber
                  breach, discovery of the untruth of any representation of
                  Subscriber, or where directed by the respective regulatory
                  body/ exchange in its regulatory authority, VENDOR may
                  terminate this Agreement with not less than three (3) days
                  written notice to Subscriber.Upon signing this Agreement by
                  Subscriber, Subscriber acknowledges and agrees that this
                  Agreement will immediately become effective until the
                  termination of this Agreement. Subscriber acknowledges that
                  Exchange/s, when required to do so in fulfillment of statutory
                  obligations, may by notice to VENDOR unilaterally limit or
                  terminate the right of any or all Persons to receive or use
                  Market Data and that VENDOR will immediately comply with any
                  such notice and will terminate or limit the furnishing of
                  Market Data and confirm such compliance by notice to
                  Exchange/s. In addition to terminations permitted under the
                  Client agreements, this Agreement may be terminated by either
                  Party with thirty (30) days written notice to the other Party.
                  VENDOR may also alter any term of this Agreement with ninety
                  (90) days written notice to Subscriber, and any use after such
                  date is deemed acceptance of the new terms. In the event of
                  Subscriber breach, discovery of the untruth of any
                  representation of Subscriber, or where directed by the
                  respective regulatory body/ exchange in its regulatory
                  authority, VENDOR may terminate this Agreement with not less
                  than three (3) days written notice to Subscriber.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    11. ACTIONS TO BE TAKEN IN FULFILLMENT OF STATUTORY
                    OBLIGATIONS.{" "}
                  </b>
                  Subscriber agrees that Exchanges, when required to do so in
                  fulfillment of its statutory obligations, may, temporarily or
                  permanently, unilaterally condition, modify or terminate the
                  right of any or all individuals or entities to receive or use
                  Market Data. VENDOR shall undertake reasonable efforts to
                  notify Subscriber of any such condition, modification or
                  termination, and Subscriber shall promptly comply with any
                  such notice within such period of time as may be determined in
                  good faith by Exchange/s to be necessary, consistent with its
                  statutory obligations.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    12. GOVERNING LAWS AND DISPUTE RESOLUTION.{" "}
                  </b>
                  This Agreement shall be construed in accordance with the laws
                  of the DIFC. All disputes arising in connection with this
                  Agreement shall be finally settled by three arbitrators, one
                  arbitrator to be appointed by each party and accordingly the
                  said two arbitrators shall within twenty-one days or as
                  applicable under the DIFC Arbitration law, appoint a third
                  arbitrator, who shall act as a Chairman. The Arbitral Tribunal
                  shall be governed in accordance with the DIFC Arbitration
                  laws. The Arbitration shall be conducted in English language.
                  The place of Arbitration shall be the DIFC. Both parties agree
                  that the award passed by the Arbitral Tribunal shall be kept
                  confidential from the public, competitors, customers and
                  agents. The decision of the Arbitral Tribunal shall be final
                  and binding upon the parties to this contract and both the
                  parties agree to accept and abide by the award. The language
                  of the arbitration shall be English.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    13. NOTICES; NOTIFICATION OF CHANGES.{" "}
                  </b>
                  All notices and other communications (except for invoices)
                  required to be given in writing under this Agreement shall be
                  directed to the signatories or, in the alternative, to the
                  individuals identified in subsections and (b) below. Notices
                  shall be deemed to have been duly given: (i) upon actual
                  receipt (or date of first refusal) by the parties, or (ii)
                  upon constructive receipt (or date of first refusal) if sent
                  by certified mail, return receipt requested, or any other
                  delivery method that actually obtains a signed delivery
                  receipt, to the addresses stated at the beginning of this
                  Agreement or to such other address as any party hereto shall
                  hereafter specify by prior written notice to the other party,
                  or (iii) upon posting the notice or other communication on the
                  website. If an email address is provided, VENDOR may, in lieu
                  of the above, give notice to or communicate with Subscriber by
                  email or to such other email address or persons as Subscriber
                  shall hereafter specify by prior written notice. By providing
                  an email address, Subscriber agrees that any receipt received
                  by VENDOR from Subscriber’s service provider or internet
                  computer server indicating that the email was received shall
                  be deemed proof that Subscriber received the message.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">14. MISCELLANEOUS </b>
                  Subscriber shall not assign all or any part of the Agreement
                  without the prior written consent of VENDOR.<br></br>In the
                  event of any conflict between the terms and conditions of the
                  Agreement and any other agreement relating to Subscriber’s
                  receipt and use of Market Data, the terms and conditions of
                  this Agreement will prevail. VENDOR has the right to modify or
                  amend the content of this Agreement at any time and shall
                  announce such modification or amendment at VENDOR’s official
                  website and does not need to obtain Subscriber’s prior
                  approval. Any modified or amended term shall take effect upon
                  the date of the announcement No failure on the part of VENDOR
                  to exercise, no delay in exercising and no course of dealing
                  with respect to any right, power or privilege under this
                  Agreement shall operate as a waiver thereof, nor shall any
                  single or partial exercise of any such right, power or
                  privilege preclude any other or further exercise thereof or
                  the exercise of any other right, power or privilege under this
                  Agreement.In case of any terms hereof being fully or partially
                  ineffective or unenforceable due to whatever reason, the other
                  terms hereof shall remain valid and binding.<br></br>
                  “EXCHANGE/S, ITS HOLDING COMPANIES AND/OR ANY SUBSIDIARIES OF
                  SUCH HOLDING COMPANIES ENDEAVOUR TO ENSURE THE ACCURACY AND
                  RELIABILITY OF MARKET DATA PROVIDED BUT DO NOT GUARANTEE ITS
                  ACCURACY OR RELIABILITY AND ACCEPT NO LIABILITY (WHETHER IN
                  CONTRACT, TORT OR OTHERWISE) FOR ANY LOSS OR DAMAGE ARISING
                  FROM ANY INACCURACIES OR OMISSIONS”
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">
                    15. THIRD PARTY BENEFICIARIES.{" "}
                  </b>
                  All rights of VENDOR under this Agreement shall also be
                  extended to all Affiliates, Exchanges,each of which is
                  expressly made a third-party beneficiary of this Agreement.
                  Among other provisions, this provision accords to the
                  foregoing Exchanges the rights, obligations and privileges of
                  this Agreement relating to the jurisdiction and venue of legal
                  actions or proceedings, governing law and indemnification.
                </div>
                <div className="mt-sm txt-align-justify">
                  <b className="txt-color-heading-2">16. DEFINITIONS.</b>
                  <div className="mt-sm pl-md">
                    <div className="txt-align-justify">
                      <b className="txt-color-heading-2">a&#41; Affiliate - </b>
                      means any individual, corporation, company, partnership,
                      limited partnership, limited liability company, trust,
                      association or other entity that, directly or indirectly
                      through one or more intermediaries, controls, is
                      controlled by, or is under the common control with such
                      party.
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">
                        b&#41; Claims or Losses -{" "}
                      </b>
                      means any and all liabilities, obligations, losses,
                      damages, penalties, claims, actions, suits, costs,
                      judgments, settlements and expenses of whatever nature,
                      whether incurred by or issued against an indemnified party
                      or a third party, including, without limitation, (a)
                      indirect, special, punitive, consequential or incidental
                      loss or damage, (including, but not limited to, trading
                      losses, loss of anticipated profits, loss by reason of
                      shutdown in operation or increased expenses of operation
                      or other indirect loss or damage), and (b) administrative
                      costs, investigatory costs, litigation costs and auditors'
                      and attorneys' fees and disbursements (including in-house
                      personnel’s).
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">
                        c&#41; Client Agreement -{" "}
                      </b>
                      means the agreement signed by the Subscriber with VENDOR
                      or its relevant Affiliate as applicable for the purpose of
                      trading financial instruments.
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">
                        d&#41; Exchange/s -{" "}
                      </b>
                      means the relevant exchanges from which VENDOR and/or its
                      subsidiaries and Affiliates obtain Market Data to be
                      provided to the Subscriber.
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">
                        e&#41; Market Data -{" "}
                      </b>
                      means including but not limited to, opening, and closing
                      prices, high-low prices, settlement prices, real-time
                      buying and selling prices, open interest information, last
                      sale prices, price limits, requests for quotations, fixing
                      prices, data curves, estimated and actual volume data,
                      contract specifications and advance or delayed messages.
                      With respect to Subscriber’s obligations under the
                      Agreement, Market Data also includes information, data and
                      materials that convey information to Subscriber
                      substantially equivalent to Market Data.
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">f&#41; Or - </b>
                      Includes the word "and."
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">g&#41; Person - </b>
                      means any natural person, proprietorship, corporation,
                      partnership or other entity whatsoever.
                    </div>
                    <div className="mt-sm txt-align-justify">
                      <b className="txt-color-heading-2">
                        h&#41; Subscriber -{" "}
                      </b>
                      means all Non-Professional and Professional Subscribers.
                      All Subscribers are deemed Professional unless they are
                      qualified as Non-Professional.
                      <div className="mt-sm">
                        <b className="txt-color-heading-2">
                          Non-Professional Subscriber -{" "}
                        </b>
                        means Any natural person who:
                        <div className="pl-md">
                          <div className="mt-sm">
                            <b className="txt-color-heading-2">a.</b>
                            is using the Market Data only for personal use, and
                            NOT for any business purpose.
                          </div>
                          <div className="mt-sm">
                            <b className="txt-color-heading-2">b. </b>
                            is NOT currently engaged as an "investment adviser"
                            in any capacity.
                          </div>
                          <div className="mt-sm txt-align-justify">
                            <b className="txt-color-heading-2">c. </b>
                            uses the service solely in relation to the
                            management of their personal funds and be limited to
                            managing their own assets, and not be used in
                            connection with the management of any other assets
                            of any third party(ies) in any capacity.
                          </div>
                          <div className="mt-sm">
                            <b className="txt-color-heading-2">d. </b>
                            does not distribute or provide information to any
                            third party in any manner.
                          </div>
                          <div className="mt-sm txt-align-justify">
                            <b className="txt-color-heading-2">e. </b>
                            is neither currently registered with any securities
                            agency, regulatory or self-regulatory body nor
                            qualified as a professional securities trader or
                            investment adviser with any country, exchange,
                            financial regulatory authority, professional
                            association or recognized professional body
                            (e.g.,FINRA, SEC, FCA, CSA and any other similar
                            professional bodies.)
                          </div>
                          <div className="mt-sm txt-align-justify">
                            <b className="txt-color-heading-2">f. </b>
                            does not act on behalf of an institution that
                            engages in brokerage,banking, investment, or
                            financial activities.
                          </div>
                          <div className="mt-sm txt-align-justify">
                            <b className="txt-color-heading-2">g. </b>
                            does not have a job that entails the buying or
                            selling of securities or commodities on behalf of an
                            employer and is not a subcontractor or independent
                            contractor for a company, where they use market data
                            to make investment/trading decisions.
                          </div>
                          <div className="mt-sm txt-align-justify">
                            <b className="txt-color-heading-2">h. </b>
                            does not contract for, receive, or use information
                            on behalf of any other person or entities including
                            but not limited to any companies, funds, trusts, or
                            any other form of entities.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-sm">
                      <div className="txt-align-justify">
                        <b className="txt-color-heading-2">
                          Professional Subscriber -
                        </b>
                        means all other persons who do not meet the definition
                        of Non-Professional Subscriber.
                      </div>
                      <div className="mt-sm pl-sm txt-align-justify">
                        <b className="txt-color-heading-2"> i. System : </b>
                        mean any system the VENDOR or its Affiliate has
                        developed for the dissemination of Market Data.
                      </div>
                      <div className="mt-sm pl-sm txt-align-justify">
                        <b className="txt-color-heading-2">
                          {" "}
                          j. VENDOR's Service :{" "}
                        </b>
                        means the services from VENDOR or its Affiliate,
                        including the data processing equipment, software and
                        communications facilities related thereto, for
                        receiving, processing, transmitting, using and
                        disseminating the Market Data to or by Subscriber.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-sm">
          <b className="txt-weight-bold">ACCEPTED AND AGREED</b>
          <div className="mt-sm txt-align-justify">
            I, the "Subscriber" to which the preceding terms and conditions
            refer, acknowledge that I have read the preceding terms and
            conditions of this Agreement, that I understand them and that I
            hereby manifest my assent to, and my agreement to comply with those
            terms and conditions by signing below.
          </div>
          <div className="mt-sm">I acknowledge that:</div>
          <div className="mt-sm pl-md">
            <div>
              <b className="txt-color-heading-2">1. </b>I have read and agree
              with the above information.
            </div>
            <div className="mt-sm txt-align-justify">
              <b className="txt-color-heading-2">2. </b>I am fully aware that I
              am declaring jointly to both the Exchanges and VENDOR or its
              service Providers.
            </div>
            <div className="mt-sm txt-align-justify">
              <b className="txt-color-heading-2">3. </b>
              VENDOR reserves the right to impose retroactive penalty charges on
              me at professional rates should I fail to declare accurately and
              truthfully.
            </div>
          </div>
        </div>
        <div className="txt-weight-bold mt-lg txt-color-danger">
          SUBSCRIBER INSTRUCTIONS: Please sign and complete one of the two
          signature blocks below:
        </div>

        <div className="b-sm-shaded-2 m-md-n p-md mt-md">
          <div className="mt-sm txt-size-sm txt-weight-bold">
            Individual Subscriber (Non-Professional Subscriber)
          </div>
          <br />
          <div className="mt-sm">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">User ID</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                                ${
                                                  errors.subscriber_userID
                                                    ? "b-xs-shaded-4"
                                                    : ""
                                                }`}
                  placeholder="User ID"
                  type="text"
                  disabled
                  name="subscriber_userID"
                  value={inputValues.subscriber_userID}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriber_userID && (
                  <span style={{ color: "red" }}>
                    {errors.subscriber_userID}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">By</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 fst-italic ${
                    errors.subscriberSignature ? "b-xs-shaded-4" : ""
                  }`}
                  placeholder="Signature"
                  type="text"
                  name="subscriberSignature"
                  value={inputValues.subscriberSignature}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberSignature && (
                  <span style={{ color: "red" }}>
                    {errors.subscriberSignature}
                  </span>
                )}
              </div>
            </div>
            <div className="text-end">(Individual Subscriber Signature)</div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Name of Signatory</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberName ? "b-xs-shaded-4" : ""
                  }`}
                  placeholder="Name of Signatory"
                  type="text"
                  name="subscriberName"
                  value={inputValues.subscriberName}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberName && (
                  <span style={{ color: "red" }}>{errors.subscriberName}</span>
                )}
              </div>
            </div>
            <div className="text-end"> (Print Name of Person Signing)</div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Address</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberAddress ? "b-xs-shaded-4" : ""
                  }`}
                  placeholder="Address"
                  type="text"
                  name="subscriberAddress"
                  value={inputValues.subscriberAddress}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberAddress && (
                  <span style={{ color: "red" }}>
                    {errors.subscriberAddress}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">City</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberCity ? "b-xs-shaded-4" : ""
                  }`}
                  type="text"
                  placeholder="Employer City"
                  name="subscriberCity"
                  value={inputValues.subscriberCity}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberCity && (
                  <span style={{ color: "red" }}>{errors.subscriberCity}</span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Country</div>
              <div className="w-75">
                <input
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberCountry ? "b-xs-shaded-4" : ""
                  }`}
                  placeholder="Country"
                  type="text"
                  name="subscriberCountry"
                  value={inputValues.subscriberCountry}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberCountry && (
                  <span style={{ color: "red" }}>
                    {errors.subscriberCountry}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Email</div>
              <div className="w-75">
                <input
                  type="text"
                  className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                    errors.subscriberEmail ? "b-xs-shaded-4" : ""
                  }`}
                  id="email"
                  placeholder="Enter email"
                  name="subscriberEmail"
                  value={inputValues.subscriberEmail}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
                {errors.subscriberEmail && (
                  <span className="txt-size-xs txt-color-danger ">
                    {errors.subscriberEmail}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Date</div>
              <div className="w-75">
                <input
                  className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 "
                  placeholder="Date"
                  type="text"
                  name="date"
                  value={initialData.date || ""}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="display-flex justify-content-between align-items-center mt-md">
            <div className="w-25">Internal Reference</div>
            <div className="w-75">
              <input
                className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                  errors.subscriberInternalReference ? "b-xs-shaded-4" : ""
                }`}
                placeholder="Internal Reference"
                type="text"
                name="subscriberInternalReference"
                value={inputValues.subscriberInternalReference}
                onBlur={handleBlur}
                onChange={(event) => {
                  onChange(event.target.name, event.target.value);
                  handleInputChange(event.target.name, event.target.value);
                }}
              />
              {errors.subscriberInternalReference && (
                <span style={{ color: "red" }}>
                  {errors.subscriberInternalReference}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAgreement;
