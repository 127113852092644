/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
export enum ErrorCode {
    NotValidForTotalView = 'EXGSUBAGR_ERR_108',
    InvalidDowngradeUser = 'EXGSUBAGR_ERR_109'
}