import { createAllAgreedMessageObject } from "../../util/helper/ObjectDataCreationHelper";
import { sendMessageToParentApp } from "../../util/UtilService";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const SubmissionSuccess = ({
  subscribeUserData,
}: {
  subscribeUserData: any;
}) => {
  const handleProceed = async () => {
    const allAgreedMessage = createAllAgreedMessageObject(subscribeUserData);
    // Message sender to the parent application
    sendMessageToParentApp(allAgreedMessage);
  };

  return (
    <div className="width-100-vw height-100-vh bg-color-paper-1">
      <div className="position-absolute top-50 start-50 translate-middle b-sm-shaded-5">
      <div className="b-b-sm-shaded-5">
        <div className="txt-size-md text-white text-center bg-color-paper-5 ptb-md">
          Success
        </div>
        <div className="txt-size-sm text-center mb-1 p-xl txt-color-success d-flex flex-column align-items-center">
          <CheckCircleOutlineIcon color="success" style={{ fontSize: 80 }} />
          You have submitted all the agreements and your subscription request
          successfully sent.
        </div>
      </div>
      <div className="display-flex jc-center ptb-md">
        <button
          className="p-sm txt-size-xs txt-color-heading-1 bg-color-paper-2 b-none"
          onClick={handleProceed}
        >
          Finish and Close
        </button>
        </div>
      </div>
    </div>
  );
};
export default SubmissionSuccess;