/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { getAppConfig } from '../config-management';

import InitialPage from '../components/shared-components/InitialPage';
import LoadingSpinner from '../components/shared-components/LoadingSpinner';
import SubmissionSuccess from '../components/shared-components/SubmissionSuccess';
import Confirmation from '../components/shared-components/Confirmation';
import InvalidSubscriberPreviewer from '../components/TotalView-Validation-Components/InvalidSubscriberPreviewer';
import InvalidDowngradePreviewer from '../components/TotalView-Validation-Components/InvalidDowngradePreviewer';

import { fetchRequiredAgreements, saveUserAgreementdata, validateUserWithOMS, cancelAgreementSubmission } from '../service/AgreementViewService';
import { validateFormDataFromAgreementDataResponse } from '../util/helper/AgreementValidationHelper';
import { encryptWithPublicKey, publicKey } from '../util/encryption/encryptUserData';

import { AgreementID } from '../enums/agreementID.enum';
import { Message } from '../enums/message.enum';
import { SigningState } from '../enums/signingState.enum';
import { ErrorCode } from '../enums/ErrorCode.enum';

import GeneralAgreement from '../components/agreements/GeneralAgreement/GeneralAgreement';
import NASDAQUtpNonPro from '../components/agreements/NASDAQUTPNonpro/NASDAQUTPNonpro';
import NYSEAgreementNonPro from '../components/agreements/NYSEAgreementNonPro/NYSEAgreementNonPro';
import OpraNonProSubscriber from '../components/agreements/OpraNonProAgreement/OpraNonProAgreement';
import CBOENonProAgreement from '../components/agreements/CBOENonProAgreement/CBOENonProAgreement';
import { getCurrentDateTime, sendMessageToParentApp } from '../util/UtilService';
import { createSubscriptionDataObject, createUserSignedDataObject } from '../util/helper/ObjectDataCreationHelper';
import CheckAgreementsSpinner from '../components/shared-components/CheckAgreementsSpinner';
import BlueOceanAgreement from '../components/agreements/BlueOceanAgreement/BlueOceanAgreement';
import NASDAQTotalViewNonPro from '../components/agreements/NASDAQTotalViewNonpro/NASDAQTotalViewNonpro';
import { AgreementFormDataResponse } from '../util/formDataMappings/AgreementFormDataMappingHelper';


const AgreementLayout = () => {

    // const [theme, setTheme] = useState('Light');
    const [subscriptionRequestID, setSubscriptionRequestID] = useState('');
    const [generatedUserID, setGeneratedESMUserID] = useState('');
    const [requiredAgreementsArray, setRequiredAgreementsArray] = useState([]);
    const [currentAgreementIndex, setCurrentAgreementIndex] = useState(0);
    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [subsData, setSubsData] = useState<{ [key: string]: any }>({})
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const [isUserDataRecieved, setisUserDataReceived] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allAgreementsSubmitted, setAllAgreementsSubmitted] = useState(false);
    const [alreadySignCompleted, setAlreadySignCompleted] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [subscribeUserData, setSubscribeData] = useState({ exchange: "", feedlevel: "", userId: "", userType: "" });
    const [resData, setResData] = useState([]);
    const [isValidated, setIsValidated] = useState(false);
    const [showInvalidTotalViewSubscriber, setShowInvalidTotalViewSubscriber] = useState(false);
    const [showInvalidDowngradeUser, setShowInvalidDowngradeUser] = useState(false);
    const config = getAppConfig();

    // Updating formData with the current date and time when current agreement index changes
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            date: getCurrentDateTime()
        }));
    }, [currentAgreementIndex]);
    
    // When the message recieved from parent window extract agreement documenets details according to the recieved message params
    
    const handleMessageFromParent = async (event: any) => {

        const allowedOrigins = config.messageListner.parentOrigin.originUrls;
        console.log('Allowed origins',allowedOrigins);
        console.log('current origin',event.origin);

        if (!allowedOrigins.includes(event.origin)) {
            setRequiredAgreementsArray([]);
            console.log('Do not match event origin',event.origin);
            return;
        }
        setisUserDataReceived(true);
        const userData = event?.data;
        try {
            const token = userData?.token;
            const subscriptionData = userData?.subscriptionData;
            setSubsData(subscriptionData);
            console.log('Subscription Data',subscriptionData);
            
            const userId = subscriptionData?.customerNumber;
            const region = subscriptionData?.region;
            // const userTheme = subscriptionData?.userTheme;
            const isAuthenticated = await validateUserWithOMS(token, userId, region);
            console.log('isAuthenticated',isAuthenticated);
            if (isAuthenticated) {
                // Create subscription details object with mapped feedlevel and user type values
                const userSubUpgradeDataOBJ = createSubscriptionDataObject(subscriptionData);
                console.log('Agreement requested user Subscription Data',userSubUpgradeDataOBJ);
                // Load agreements for recieved subscription data
                await loadAgreementsForSubscription(userSubUpgradeDataOBJ);
            } else {
                console.log('User Not Authenticated');
                setRequiredAgreementsArray([]);
            }
            
        } catch (error) {
            console.error('Error processing fetch agreements:', error);
            setRequiredAgreementsArray([]);
        } finally {
            setisUserDataReceived(false);
        }
    };
   
    
    // Extracting and set the state of the logical order of displaying agreements for given user details parameters   
    
    const loadAgreementsForSubscription = async (subscriptionData: any) => {
        setSubscribeData(subscriptionData);
    
        try {
            const response = await fetchRequiredAgreements(subscriptionData);
    
            if (response?.success) {
                console.log('Agreement Data Response', response);
        
                const responseData = response?.data;
                console.log("responseData",responseData)
                const { generatedUserID, SubscriptionID, body, AgreementCompletion, existingUserData } = responseData;
        
                setResData(existingUserData);
                setSubscriptionRequestID(SubscriptionID);
                setGeneratedESMUserID(generatedUserID);
        
                if (AgreementCompletion === SigningState.notCompleted && body.length > 0) {
                    console.log("Start updating agreement object state")
                    // State update to render the agreements
                    setRequiredAgreementsArray(body);
                } else if (AgreementCompletion === SigningState.completed && body.length === 0) {
                    setAlreadySignCompleted(true);
                }        
                setCurrentAgreementIndex(0);

            } else if (response?.errorCode === ErrorCode.NotValidForTotalView) {
                console.error('User not have L1 or higher feed level for NYSE AMEX OR NSDQ. Cannot subscribe to TotalView :', response.error);
                setShowInvalidTotalViewSubscriber(true);
            } else if (response?.errorCode === ErrorCode.InvalidDowngradeUser) {
                console.error('User is not valid for downgrade due to TotalView subscription:', response.error);
                setShowInvalidDowngradeUser(true);
            }  else {
                console.error('Failed to fetch agreements:', response.error);
                setRequiredAgreementsArray([]);
            }
        } catch (error) {
            console.error('Error fetching agreements:', error);
            setRequiredAgreementsArray([]);
        }
    };

       
    useEffect(() => {
        // Ensure currentAgreementIndex is valid and within bounds
        if (requiredAgreementsArray.length > 0 && currentAgreementIndex < requiredAgreementsArray.length) {
            const currentAgreement = requiredAgreementsArray[currentAgreementIndex];

            if (currentAgreement) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ...AgreementFormDataResponse(resData, currentAgreement),
                    date: getCurrentDateTime()
                }));
            }
        } 
    }, [resData, requiredAgreementsArray, currentAgreementIndex]);

    // Handle the submission process of each agreement component displayed according to the logical 
    // order extracted by dependancies
    const handleSubmitAgreement = async () => {
        setIsSubmitting(true);
        setIsCancelling(false) 
        const currentAgreement = requiredAgreementsArray[currentAgreementIndex];
        const { agreementID } = currentAgreement;
        
        // Validate each agreement response data.
        const validatedFormDataMap = validateFormDataFromAgreementDataResponse(formData, currentAgreement);
    
        // If the validation failed in one of the agreement responses it stops the submission of a particular agreement
        if (!validatedFormDataMap?.success) {
            const invalidFields = validatedFormDataMap?.invalidFields;
            console.error(`Error validating form data for Agreement ${agreementID}:`, `Invalid Fields ${invalidFields}`);
            setInvalidFields(invalidFields || []);
            setIsSubmitting(false);
            return;
        }
        setInvalidFields([]);

        try {
            // Encrypt personal data with the public key
            const encryptedData = await encryptWithPublicKey(validatedFormDataMap.data, publicKey);
            const userSignedInfo = createUserSignedDataObject( subscribeUserData, generatedUserID, subscriptionRequestID, agreementID, encryptedData);
            // Save personal data
            const response = await saveUserAgreementdata(userSignedInfo);
            console.log('Agreement Data save response:', response);
            console.log("formData",formData);
            if (response?.success) {
                // If the data saving is successful in the displayed agreement, it increases the current agreement index and displays the next agreement
                setCurrentAgreementIndex((prevIndex) => {
                    const newIndex = prevIndex + 1;        
                    if (newIndex === requiredAgreementsArray.length) {
                        setAllAgreementsSubmitted(true);                       
                    }        
                    return newIndex;
                });
            } else {
                console.error("Submission failed:", response.message);
                setIsSubmitting(false);
            }           
        } catch (error) {
            console.error(`Error submitting Agreement ${agreementID}:`, error);
            setIsSubmitting(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    // When the user click cancel button currently signing process will stop and close displaying agreemnts.
    // When it come to multiple agreemnts submissions if user cancel submisson after one agreement sbmitted 
    // the signed agreemts will delete from our side and subscription status going to cancel state 
    const cancelSubmission = async () => {
        setIsCancelling(true);
        setIsSubmitting(false);
        const response = await cancelAgreementSubmission(subscriptionRequestID);
        if(response?.success){
            setRequiredAgreementsArray([]);
            setCurrentAgreementIndex(0);
            setFormData({});            
            setAllAgreementsSubmitted(false);
            
            // Send cancel message to parent
            sendMessageToParentApp({ messageType: Message.cancelSubmission });
        }else{
            console.error("Error cancelling agreement submission", response.message);
            setIsCancelling(false);
        }
        setIsCancelling(false);     
    };

    // update form data object state dynamically based on user input changes in each agreemnt component.
    const handleFormDataChange = (fieldName: any, value: any) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName]: value,
            date: getCurrentDateTime()
        }));
    };

    // Render each agreement according to the current agreement state. The current agreement state will update
    // after each successfull submission
    const renderCurrentAgreement = (currentAgreementIndex: any) => {

        if (!requiredAgreementsArray || requiredAgreementsArray.length === 0)
            return <InitialPage />;

        if (isSubmitting) return <LoadingSpinner />;

        const agreement: any = requiredAgreementsArray[currentAgreementIndex];
        
        switch (agreement.agreementID) {
            case AgreementID.GENERAL:
                 return <GeneralAgreement onChange={handleFormDataChange} initialData={formData}   resData={resData}  setIsValidated={setIsValidated} subsData= {subsData}/>;
            case AgreementID.NASDAQ_NP:
                return <NASDAQUtpNonPro onChange={handleFormDataChange}  initialData={formData} resData={resData} setIsValidated={setIsValidated} subsData= {subsData}/>;
            case AgreementID.NYSE_AMEX_NP:
                return <NYSEAgreementNonPro onChange={handleFormDataChange} initialData={formData}  resData={resData} setIsValidated={setIsValidated} subsData= {subsData} />;
            case AgreementID.NASDAQ_TV_NP:
                return <NASDAQTotalViewNonPro onChange={handleFormDataChange}  initialData={formData}  resData={resData} setIsValidated={setIsValidated} subsData= {subsData}/>;
            case AgreementID.OPRA_NP:
                return <OpraNonProSubscriber onChange={handleFormDataChange} initialData={formData}  resData={resData} setIsValidated={setIsValidated} subsData= {subsData}/>;
            case AgreementID.CBOE_NP:
                return <CBOENonProAgreement onChange={handleFormDataChange} initialData={formData}  resData={resData} setIsValidated={setIsValidated} subsData= {subsData}/>;
            case AgreementID.BLUE_OCEAN:
                return <BlueOceanAgreement onChange={handleFormDataChange}  initialData={formData}  resData={resData} setIsValidated={setIsValidated} subsData= {subsData}/>;
            default:
                return <InitialPage />;
        }
    };

    // Add an Event lIstner to catch messages from web app. This will intitialize and run once 
    // when component mounts and remove it when the component unmounts.
    useEffect(() => {
        window.addEventListener('message', handleMessageFromParent);
        // Send acknowledgment message to the parent window when the component mounts
        sendMessageToParentApp({ messageType: Message.appReady });
        return () => {
            window.removeEventListener('message', handleMessageFromParent);
        };
    }, []);

    return (
        <div>
            <br />
            {isUserDataRecieved ? (
                <CheckAgreementsSpinner />
            ) : (
                <div>
                    { showInvalidTotalViewSubscriber ? (                    
                        <InvalidSubscriberPreviewer />
                    ): showInvalidDowngradeUser ? (
                        <InvalidDowngradePreviewer />
                    ) : alreadySignCompleted ? (
                        <Confirmation 
                            subscriptionInfo={{
                                ...subscribeUserData, 
                                subscriptionRequestID, 
                                generatedUserID 
                            }} 
                    />
                    ) : allAgreementsSubmitted ? (
                        <SubmissionSuccess subscribeUserData={subscribeUserData} />
                    ) : (
                        <div>
                            <div>
                                {renderCurrentAgreement(currentAgreementIndex)}
                            </div>
                            <br />
                            {currentAgreementIndex < requiredAgreementsArray.length && (
                            <div className='display-flex justify-content-end'>
                                    <button className="txt-color-body-shaded-4 b-sm-shaded-5 bg-color-paper-1 m-sm p-sm txt-size-xs" onClick={cancelSubmission} disabled={isCancelling || isSubmitting}>{isCancelling ? 'Cancelling...' : 'Cancel'}</button>  
                                    {isValidated  ?
                                    <button className="m-sm p-sm mr-lg txt-size-xs txt-color-heading-1 bg-color-paper-2 b-none" onClick={handleSubmitAgreement} disabled={isSubmitting}>{isSubmitting ? 'Submitting Agreement...' : 'Agree and Continue'}</button> 
                                    : <button className="m-sm p-sm mr-lg txt-size-xs txt-color-heading-1 bg-color-paper-5 b-none" onClick={handleSubmitAgreement} disabled>{isSubmitting ? 'Submitting Agreement...' : 'Agree and Continue'}</button>
                                }
                                </div>
                            )}
                        </div>
                    )}
                    <br />
                </div>
            )}
            <br />
        </div>
    );

}

export default AgreementLayout;