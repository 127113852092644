import { useEffect, useState } from "react";
import { useInputHandlers } from "./BluwOceanAgreementValidations";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

const BlueOceanAgreement = ({
  onChange,
  initialData,
  resData,
  setIsValidated,
  subsData,
}: any) => {
  console.log("Web Tech Data", subsData?.customerNumber);

  const initialValues = {
    subscriberName:
      subsData?.userInfo?.firstName && subsData?.userInfo?.lastName
        ? `${subsData.userInfo.firstName} ${subsData.userInfo.lastName}`
        : resData?.FirstName && resData?.LastName
        ? `${resData.FirstName} ${resData.LastName}`
        : "",
    subscriberSignature: "",
  };
  const { inputValues, errors, handleInputChange, handleBlur } =
    useInputHandlers(initialValues);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    const allFieldsFilled = Object.values(inputValues).every(
      (value: any) => value.trim() !== ""
    );
    const noErrors = Object.values(errors).every((error) => error === "");
    setIsValidated(allFieldsFilled && noErrors);
  }, [inputValues, errors]);

  // Function to handle radio button change
  const handleRadioChange = (name: any, value: any) => {
    setSelectedValue(value);
  };

  return (
    <div className="txt-size-xs p-lg txt-color-default">
      <div style={{ borderBottom: "3px solid rgb(34, 34, 34)" }}>
        <div className="display-flex jc-center txt-size-md txt-weight-bold pb-md">
          Blue Ocean End User Agreement
        </div>
      </div>
      <div className="txt-weight-extra-bold mt-md txt-align-justify">
        Distributor may not modify or waive any term of this Agreement. Any
        attempt to modify this Agreement, except by Blue Ocean Technologies LLC
        (“Blue Ocean”) or its affiliates, is void.
      </div>
      <div className="txt-align-justify mt-md">
        This Blue Ocean End User Agreement (this “Agreement”), with an effective
        date as of the last date executed on the signature page hereof, is made
        by and between the Market Data Redistributor referenced below
        (“Distributor”) and the end user referenced below (“End User”).
      </div>
      <div>
        <div className="mt-md">
          <span className="txt-size-sm txt-weight-bold">1. Definitions. </span>
          Capitalized terms used herein shall have the meanings set forth in
          this Section 1.
        </div>
        <div className="ml-sm txt-align-justify">
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              “Claims and Losses”
            </span>
            means any and all liabilities, obligations, losses, damages,
            penalties, claims, actions, suits, proceedings, costs, judgments,
            settlements and expenses of any nature, whether incurred by or
            issued against an indemnified party or a third party, including,
            without limitation, (a) indirect, special, punitive, consequential
            or incidental loss or damage; and (b) administrative costs,
            investigatory costs, litigation costs and auditors’ and attorneys’
            fees and expenses (including in-house personnel).
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">“Market Data”</span>{" "}
            or
            <span className="txt-size-xs txt-weight-bold">“Information”</span>
            means certain data and other information: (a) disseminated by a
            System relating to securities or other financial instruments,
            products, vehicles, or other means; or (b) related to Persons
            regulated by Blue Ocean or to activities of Blue Ocean; or (c)
            gathered by Blue Ocean from other sources.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              “Blue Ocean Indemnified Parties”
            </span>
            means, collectively, Blue Ocean, its affiliates and third-party
            information providers, and its and their respective owners,
            officers, directors, employees, contractors and agents.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              “Non-Professional End User”
            </span>
            means a natural person or qualifying trust that uses Exchange Data
            only for personal purposes and not for any commercial purpose and,
            for a natural person who works in the United States, is not: (i)
            registered or qualified in any capacity with the Securities and
            Exchange Commission, the Commodities Futures Trading Commission, any
            state securities agency, any securities exchange or association, or
            any commodities or futures contract market or association; (ii)
            engaged as an “investment adviser” as that term is defined in
            Section 202(a)(11) of the Investment Advisors Act of 1940 (whether
            or not registered or qualified under that Act); or (iii) employed by
            a bank or other organization exempt from registration under federal
            or state securities laws to perform functions that would require
            registration or qualification if such functions were performed for
            an organization not so exempt; or, for a natural person who works
            outside of the United States, does not perform the same functions as
            would disqualify such person as a Non-Professional User if he or she
            worked in the United States.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">“Person”</span>
            means any individual, corporation, limited liability company, trust,
            joint venture, association, company, limited or general partnership,
            unincorporated organization, or other entity. “Professional End
            User” means all other Persons who do not meet the definition of
            Non-Professional End User.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              “Regulatory Requirements”
            </span>
            means (a) the rules, regulations, interpretations, decisions,
            opinions, orders and other requirements of the Securities and
            Exchange Commission or other regulatory authorities, as may be
            applicable; (b) the rules and regulations, disciplinary decisions
            and rule interpretations of Blue Ocean; (c) Blue Ocean’s decisions,
            policies, interpretations, user guides, operating procedures,
            specifications, requirements and other documentation that is
            regulatory or technical in nature published on Blue Ocean’s website
            or successor website; and (d) all other applicable April 27, 2023
            Page 2 of 7 laws, statutes, rules, regulations, orders, decisions,
            interpretations, opinions and other legal or regulatory
            requirements.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">“End User”</span>
            means, collectively, all Non-Professional End Users and Professional
            End Users.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">“System”</span>
            means any system Blue Ocean or its affiliates have developed for
            creation and/or dissemination of Market Data.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">“Distributor”</span>
            means “Data Recipient,” as that term is defined in the Blue Ocean
            Market Data Agreement, as may be modified from time to time.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              “Distributor’s Service”
            </span>
            means the service from a Distributor, including the data processing
            equipment, software, and communications facilities related thereto,
            for receiving, processing, transmitting, using, and disseminating
            Exchange Data to or by End User.
          </div>
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">2. Use of Data.</span>
        </div>
        <div className="ml-sm txt-align-justify">
          <div className="mt-md">
            End User may not sell, lease, furnish or otherwise permit or provide
            access to Exchange Data to any other Person or to any other office
            or place. End User will not engage in the operation of any illegal
            business use or permit anyone else to use Exchange Data, or any part
            thereof, for any illegal purpose or violation of any Regulatory
            Requirements. End User may not present Exchange Data rendered in any
            unfair, misleading, or discriminatory format. End User shall take
            reasonable security precautions to prevent unauthorized Persons from
            gaining access to Exchange Data.
          </div>
          <div className="mt-md">
            <span className="txt-size-xs txt-weight-bold">
              <u>Use by Non-Professional End Users.</u>
            </span>
            Exchange Data is licensed only for personal, non-commercial use by a
            NonProfessional End User. By representing to Distributor that End
            User is a Non-Professional End User, or by continuing to receive
            Exchange Data at a Non-Professional End User rate, End User is
            affirming to Distributor and Blue Ocean that End User meets the
            definition of Non-Professional End User as set forth herein. A
            Non-Professional End User shall comply promptly with any reasonable
            request from Blue Ocean, or its designee, for information regarding
            the NonProfessional End User’s receipt, processing, display, use,
            and redistribution of Exchange Data. Use by Professional End Users.
            Exchange Data is licensed for internal business use and/or personal
            use by a Professional End User. Professional End User may, on a
            non-continuous basis, furnish limited amounts of Exchange Data to
            customers in written advertisements, correspondence, or other
            literature or during voice telephonic conversations not entailing
            computerized voice, automated information inquiry systems, or
            similar technologies. Professional End User shall make its premises
            available to Blue Ocean, or its designee, for physical inspection of
            Distributor’s Service and of Professional End User’s use of Exchange
            Data (including review of any records regarding use of or access to
            Exchange Data and the number and locations of all devices that
            receive Exchange Data), all at reasonable times and upon reasonable
            notice, to ensure compliance with this Agreement.
          </div>
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">3. Proprietary Data.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Blue Ocean grants to End User a non-exclusive, non-transferable
          license during the term of the Agreement to receive Exchange Data
          distributed to it by Distributor and, thereafter, to use such Exchange
          Data as permitted under the terms of this Agreement and Regulatory
          Requirements. End User acknowledges and agrees that Blue Ocean and its
          affiliates have proprietary rights to Exchange Data that (a)
          originates on or relates to trading on Blue Ocean; (b) relates to
          activities that are regulated or operated by Blue Ocean; (c) Blue
          Ocean derives from Exchange Data that originates on or relates to Blue
          Ocean; and (d) is a compilation of information and data that Blue
          Ocean gathers from other sources. End User further acknowledges and
          agrees that Blue Ocean’s third party information providers may impose
          certain requirements on the use and distribution of their respective
          information and data or information derived from their information and
          data, and accordingly End User’s rights under this Agreement with
          respect to Exchange Data including or based on such third party
          information and data is subject to requirements imposed by the subject
          provider from time to time, notwithstanding terms and conditions of
          this Agreement to the contrary. In the event of any misappropriation
          or misuse by End User or anyone who accesses Exchange Data through End
          User, Blue Ocean or its affiliates or third-party information
          providers shall have the right to obtain injunctive relief for its
          respective materials. End User shall attribute the source of Exchange
          Data as appropriate under all circumstances.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">4. Payment.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          End User shall assume full and complete responsibility for the payment
          of any taxes, charges, or assessments imposed on End User or Blue
          Ocean (except for U.S. federal, state, or local incomes taxes, if any,
          imposed on Blue Ocean) by any foreign or domestic national, state,
          provincial, or local governmental bodies, or subdivisions thereof, and
          any penalties or interest relating to the provision of Exchange Data
          to End User. Interest shall be due from the date of the invoice to the
          time that the amounts that are due have been paid. To the extent
          permitted by applicable law, End User acknowledges and agrees that the
          termination of Distributor’s Service for failure to make payments
          shall not be considered an improper limitation of access by Blue
          Ocean. For Professional End Users, if any payment is due directly to
          Blue Ocean under this Agreement, payment in full is due Blue Ocean in
          immediately available funds within 30 days of the date of an invoice,
          whether or not use is made of, or access it made to, Exchange Data.
          End User agrees to pay Blue Ocean any applicable late fees on all past
          due amounts that are not the subject of a legitimate and bona fide
          dispute.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">5. System.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          End User acknowledges that Blue Ocean, in its sole discretion, may
          from time to time make modifications additions, and/or deletions to
          the System or Exchange Data or any aspect of either. Such
          modifications additions, or deletions may require corresponding
          changes to be made to Distributor’s Service. Changes or the failure to
          make timely changes by Distributor may sever, delay, or otherwise
          affect End User’s access to or use of Exchange Data. Blue Ocean shall
          not be responsible for any such effects. Blue Ocean does not endorse
          or approve any Distributor, Distributor’s Service or equipment
          utilized by Distributor or End User.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">6. Limitation of Liability.</span>
        </div>
        <div className="ml-sm">
          <div className="mt-md txt-align-justify">
            Blue Ocean Indemnified Parties shall not be liable to End User or to
            any other Person for any inaccurate or incomplete Exchange Data
            received from Blue Ocean or from Distributor, any delays,
            interruptions, errors, or omissions in the furnishing thereof, or
            any direct, indirect or consequential damages arising from or
            occasioned by said inaccuracies, delays, interruptions, errors or
            omissions.
          </div>
          <div className="mt-md txt-align-justify">
            This Section shall not relieve Blue Ocean, Distributor, End User, or
            any other Person from liability for damages that result from their
            own gross negligence or willful tortious misconduct or from personal
            injury or wrongful death claims.
          </div>
          <div className="mt-md txt-align-justify">
            Blue Ocean, Distributor, and End User understand and agree that the
            terms of this Section reflect a reasonable allocation of risk and
            limitation of liability.
          </div>
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">7. Disclaimer of Warranties.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          END USER EXPRESSLY ACKNOWLEDGES THAT Blue Ocean INDEMNIFIED PARTIES DO
          NOT MAKE ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OR ANY
          WARRANTIES OF MERCHANTABILITY, QUALITY OR FITNESS FOR A PARTICULAR
          PURPOSE.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">
            8. Third-Party Information Providers’ Limitation of Liability.
          </span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Blue Ocean’s third-party information providers shall have no liability
          for any damages, whether direct or indirect, whether lost profits,
          indirect, special, or consequential damages of End User or any other
          Person seeking relief through End User relating to the accuracy of or
          delays or omissions in any Exchange Data provided by Blue Ocean’s
          third-party information providers, even if the third-party information
          providers have been advised of the possibility of such damages. In no
          event will the liability of the third-party information providers or
          their affiliates to End User or any other Person seeking relief
          through End User pursuant to any cause of action, whether in contract,
          tort, or otherwise, exceed the fee paid by End User or any other
          Person seeking relief through End User, as applicable.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">9. Claims and Losses.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          End User agrees to indemnify and hold harmless Blue Ocean Indemnified
          Parties from any and all Claims and Losses imposed on, incurred by, or
          asserted as a result of or relating to: (a) any noncompliance by End
          User with the terms and conditions hereof; and (b) any third-party
          actions related to End User’s receipt and use of Exchange Data,
          whether authorized or unauthorized under this Agreement. Each party
          agrees to indemnify and hold harmless (and in every case, Blue Ocean
          shall be permitted to solely defend and settle) another party
          (including Blue Ocean) and their owners, subsidiaries, affiliates,
          officers, directors, employees, agents, and any related Persons,
          against any Claims and Losses arising from, involving, or relating to
          a claim of infringement or other violation of an intellectual property
          right by the indemnifying party provided that: (a) the indemnified
          party promptly notifies the indemnifying party in writing of the
          Claims and Losses; and (b) the indemnified party reasonably cooperates
          in the defense of the Claims and Losses.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">10. Termination.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          End User acknowledges that Blue Ocean, when required to do so in
          fulfillment of statutory obligations or otherwise, may by notice to
          Distributor unilaterally limit or terminate the right of any or all
          Persons to receive or use Exchange Data, or any part thereof, and that
          Distributor shall immediately comply with any such notice and
          terminate or limit the furnishing of Exchange Data and confirm such
          compliance by written notice to Blue Ocean. Any affected Person will
          have available to it such procedural protections as are provided by
          applicable Regulatory Requirements. In addition to the termination
          rights permitted under any agreement End User may have with
          Distributor, this Agreement may be terminated by End User upon 30
          days’ written notice to Distributor and by Blue Ocean upon 30 days’
          written notice either to Distributor or End User. In the event of End
          User’s breach, the discovery of the untruth of any representation or
          warranty of End User, or where directed by a regulatory authority
          having jurisdiction over Blue Ocean or a Blue Ocean affiliate, Blue
          Ocean may terminate this Agreement upon not less than 3 days’ written
          notice to End User provided either by Blue Ocean or Distributor.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">11. Notices.</span>
        </div>
        <div className="ml-sm txt-align-justify">
          <div className="mt-md">
            All communications required to be given in writing to Blue Ocean
            under this Agreement shall be directed to: Blue Ocean Technologies
            LLC
          </div>
          <div className="ml-md mt-md">
            <p>
              Brook 35 Plaza, Suite 250
              <br />
              2150 Route 35
              <br />
              Sea Girt, NJ
              <br />
              08750
              <br />
              United States
              <br />
              Email: marketdata@blueocean-technology.io
            </p>
          </div>
          <div className="mt-md txt-align-justify">
            Direct communication to End User at the last address known to
            Distributor shall be considered given (a) upon actual receipt if
            delivered by email, or (b) upon posting the notice or other
            communication on Blue Ocean’s website (www.blueocean-tech.io) or
            successor website. End User promptly shall give written notice to
            Distributor of any change in the name or place of residence or
            business at which Exchange Data is received.
          </div>
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">12. Assignment.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          This Agreement shall inure to the benefit of and shall be binding upon
          the parties hereto and their respective permitted successors and
          assigns. Neither Distributor nor End User shall assign this Agreement
          in whole or in part (including by operation of law) without the prior
          written consent of Blue Ocean, provided, however, that Blue Ocean
          shall not unreasonably withhold such consent. Notwithstanding the
          foregoing, Distributor or End User may assign this Agreement in its
          entirety to an affiliate or subsidiary without the prior written
          consent of Blue Ocean, provided that the assigning party is not
          currently in breach of this Agreement or delinquent in any fees owed
          to Blue Ocean. Blue Ocean may assign or transfer this Agreement or any
          of its rights or obligations hereunder to a related or unrelated party
          upon notice to Distributor and End User.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">13. Severability.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Each provision of this Agreement will be deemed to be effective and
          valid under applicable law, but if any provision of this Agreement is
          determined to be invalid, void, or unenforceable under any law, rule,
          administrative order or judicial decision, that determination will not
          affect the validity of the remaining provisions of this Agreement, and
          such provision shall be construed to be effective and valid to the
          fullest extent under applicable law.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">
            14. Entire Agreement; Amendment; Waiver.
          </span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          This Agreement constitutes the complete and entire agreement of the
          parties to this Agreement with respect to its subject matter and
          supersedes all prior writings or understandings. If there is any
          conflict and/or inconsistency between this Agreement and Distributor’s
          agreement with End User, the terms of this Agreement shall prevail as
          between Blue Ocean and End User. Blue Ocean may modify any term of
          this Agreement upon 60 days’ written notice either to Distributor or
          End User, and any receipt or use of Exchange Data after such date
          shall be deemed acceptance of the new term or condition. No failure on
          the part of Blue Ocean or End User to exercise, no delay in
          exercising, and no course of dealing with respect to any right, power,
          or privilege under the Agreement shall operate as a waiver thereof,
          nor shall any single or partial exercise of any such right, power, or
          privilege preclude any other or further exercise thereof or the
          exercise of any other right, power, or privilege under this Agreement.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">15. Governing Law; Venue.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          This Agreement will be governed by and interpreted in accordance with
          the internal laws of the State of New York, USA without giving effect
          to any choice or conflict of law provision or rule. End User hereby
          submits to the jurisdiction of the state and federal courts located in
          the County of New York in the State of New York for the resolution of
          any dispute arising under this Agreement.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">16. Headings.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Section headings are included for convenience only and are not to be
          used to construe or interpret this Agreement. All references contained
          herein to sections or subsections shall refer to the sections or
          subsections of this Agreement, unless specific reference is made to
          the sections or subsections of another document.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">17. Third Party Beneficiary.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Distributor and End User hereby designate Blue Ocean as a third-party
          beneficiary of this Agreement, having the right to enforce any
          provision herein.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">18. Cumulative Remedies.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          Except as otherwise limited herein, all rights and remedies provided
          in this Agreement are cumulative and not exclusive, and the exercise
          by either party of any right or remedy does not preclude the exercise
          of any other rights or remedies that may now or subsequently be
          available at law, equity, by statute, in any other agreement between
          the parties or otherwise.
        </div>
      </div>

      <div className="mt-md">
        <div className="txt-weight-bold">
          <span className="txt-size-sm">19. Counterparts.</span>
        </div>
        <div className="mt-md ml-sm txt-align-justify">
          This Agreement may be executed in one or more counterparts, which
          shall each be considered an original but all of which shall constitute
          one and the same Agreement.
        </div>
      </div>
      <div className="mt-md">
        <div className="txt-align-justify">
          <div className="">
            <span className="txt-size-xs txt-weight-bold">
              IN WITNESS WHEREOF the{" "}
            </span>
            parties hereto have caused this Agreement to be executed by their
            duly authorized officers.
          </div>
          <div className="txt-weight-bold mt-md txt-align-justify">
            To execute this Agreement, you must be 18 years of age and you must
            designate yourself as either a NonProfessional End User or
            Professional End User (see Section 2 above).
          </div>
        </div>
      </div>

      <div className="mt-md display-flex">
        <div className="w-25">
          <span className="txt-size-sm txt-weight-bold">End User Type:</span>
        </div>
        <div className="w-75 display-flex mb-md">
          <div className="mt-sm-n">
            {/* <input
                                                    type="radio"
                                                    name="subscriber_type"
                                                     className="mr-sm"
                                                    value="Individual"
                                                    onChange={(event) => {
                                                        onChange(event.target.name, event.target.value);
                                                        handleRadioChange(event.target.name, event.target.value);
                                                    }}
                                                />
                                                     
                                                Individual - Complete Section A.
                                                </label>
                                            
                                     
                                            <label className="display-flex mt-sm">
                                                <input
                                                    type="radio"
                                                    name="subscriber_type"
                                                    className="mr-sm"
                                                    value="Firm"
                                                    disabled
                                                    onChange={(event) => {
                                                        onChange(event.target.name, event.target.value);
                                                        handleRadioChange(event.target.name, event.target.value);
                                                    }}
                                                />
                                           
                                                Firm or Organization - Complete Section B. */}

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="subscriber_type"
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
                handleRadioChange(event.target.name, event.target.value);
              }}
            >
              <FormControlLabel
                value="Individual"
                control={<Radio />}
                label="Individual - Complete Section A."
              />
              <FormControlLabel
                value="Firm"
                control={<Radio disabled />}
                label="Firm or Organization - Complete Section B."
              />
            </RadioGroup>
          </div>
        </div>
      </div>

      <div
        className={`${selectedValue !== "Individual" ? "form-shaded" : ""}`}
        style={{
          pointerEvents: selectedValue !== "Individual" ? "none" : "auto", // Disable all inputs if not Individual
          opacity: selectedValue !== "Individual" ? 0.5 : 1, // Apply shading effect
        }}
      >
        <div className="txt-size-sm txt-weight-bold">
          A. Individual End User Information
        </div>
        <div className="ml-md">
          <div className="display-flex justify-content-between align-items-center mt-sm">
            <div className="w-25">End User Name</div>
            <div className="w-75">
              <input
                className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 ${
                  errors.subscriberName ? "b-xs-shaded-4" : ""
                }`}
                placeholder="End User NAme"
                type="text"
                name="subscriberName"
                value={inputValues.subscriberName}
                onBlur={handleBlur}
                onChange={(event) => {
                  onChange(event.target.name, event.target.value);
                  handleInputChange(event.target.name, event.target.value);
                }}
              />
              {errors.subscriberName && (
                <span style={{ color: "red" }}>{errors.subscriberName}</span>
              )}
            </div>
          </div>

          <div className="display-flex justify-content-between align-items-center mt-sm">
            <div className="w-25">Signature</div>
            <div className="w-75">
              <input
                className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 fst-italic ${
                  errors.subscriberSignature ? "b-xs-shaded-4" : ""
                }`}
                placeholder="Signature"
                type="text"
                name="subscriberSignature"
                value={inputValues.subscriberSignature}
                onBlur={handleBlur}
                onChange={(event) => {
                  onChange(event.target.name, event.target.value);
                  handleInputChange(event.target.name, event.target.value);
                }}
              />
              {errors.subscriberSignature && (
                <span style={{ color: "red" }}>
                  {errors.subscriberSignature}
                </span>
              )}
            </div>
          </div>

          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Date</div>
              <div className="w-75">
                <input
                  className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                  placeholder="Date"
                  type="text"
                  name="date"
                  value={initialData.date || ""}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="display-flex mt-sm">
            <div className="w-25">
              <span className="txt-size-sm txt-weight-bold">
                End User Status:
              </span>
            </div>
            <div className="w-75 display-flex">
              <div className="display-flex flex-column">
                {/* <label className="display-flex">
                  <input
                    type="radio"
                    name="userStatus"
                    className="mr-sm"
                    value="Professional"
                    disabled
                    onChange={(event) =>
                      onChange(event.target.name, event.target.value)
                    }
                  />
                  Professional
                </label>
                <label className="display-flex mt-sm">
                  <input
                    type="radio"
                    name="userStatus"
                    className="mr-sm"
                    value="Non-Professional"
                    checked
                    disabled={selectedValue !== "Individual"}
                    onChange={(event) =>
                      onChange(event.target.name, event.target.value)
                    }
                  />
                  Non-Professional*
                </label> */}
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="userStatus"
                  onChange={(event) =>
                    onChange(event.target.name, event.target.value)
                  }
                >
                  <FormControlLabel
                    value="Professional"
                    control={<Radio disabled/>}
                    label=" Professional"
                  />
                  <FormControlLabel
                    value="Non-Professional"
                    control={<Radio checked />}
                    label=" Non-Professional*"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="txt-align-justify mt-md">
        <i>
          *To qualify as a Non-Professional End User, you must meet all of the
          terms set forth in Section 2 of the Agreement.
        </i>
      </div>

      <div
        className="form-shaded mt-md"
        style={{
          pointerEvents: selectedValue !== "Individual" ? "none" : "auto", // Disable all inputs if not Individual
          opacity: selectedValue !== "Individual" ? 0.5 : 1, // Apply shading effect
        }}
      >
        <span className="txt-weight-bold txt-size-sm">
          B. Organizational End User Information
        </span>
        <div className="ml-md">
          <div className="display-flex justify-content-between align-items-center mt-md">
            <div className="w-25">End User Organization Name :</div>
            <div className="w-75">
              <input
                className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                placeholder="Organization Name"
                type="text"
                name="Organization_name"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value)
                }
                disabled
              />
            </div>
          </div>
          <div className="display-flex justify-content-between align-items-center mt-sm">
            <div className="w-25">Representative Name :</div>
            <div className="w-75">
              <input
                className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                placeholder="Representative Name"
                type="text"
                name="Representative_name"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value)
                }
                disabled
              />
            </div>
          </div>
          <div className="display-flex justify-content-between align-items-center mt-sm">
            <div className="w-25">Title :</div>
            <div className="w-75">
              <input
                className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                placeholder="Title"
                type="text"
                name="Representative_title"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value)
                }
                disabled
              />
            </div>
          </div>
          <div className="display-flex justify-content-between align-items-center mt-sm">
            <div className="w-25">Signature: </div>
            <div className="w-75">
              <input
                className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                placeholder="Signature"
                type="text"
                name="Representative_name_signature"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value)
                }
                disabled
              />
            </div>
          </div>
          <div className="mt-md">
            <div className="display-flex justify-content-between align-items-center">
              <div className="w-25">Date</div>
              <div className="w-75">
                <input
                  className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2"
                  placeholder="Date"
                  type="text"
                  name="date"
                  value={initialData.date || ""}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="txt-align-justify mt-md pb-lg"
        style={{ borderBottom: "3px solid rgb(34, 34, 34)" }}
      >
        <i>
          *The Representative must be authorized in writing by the organization
          or firm to execute the Agreement. Blue Ocean may request documentation
          evidencing this authority.{" "}
        </i>
      </div>

      <div>
        <div className="col-12">
          <div className="txt-weight-bold txt-size-sm mt-md">
            Distributor Information (for Distributor or Data Provider Use Only)
          </div>
          <div className="ml-md">
            <div className="w-100 pb-md mt-md">
              <div className="display-flex">
                <div className="w-25">Distributor Name </div>
                <div className="w-75 border-bottom">
                  : GTN Group Holding Ltd
                </div>
              </div>
            </div>
            <div className="w-100 pb-md">
              <div className="display-flex">
                <div className="w-25">Representative Name</div>
                <div className="w-75 border-bottom">:</div>
              </div>
            </div>
            <div className="w-100 pb-md ">
              <div className="display-flex">
                <div className="w-25">Title</div>
                <div className="w-75 border-bottom">:</div>
              </div>
            </div>
            <div className="w-100 pb-md ">
              <div className="display-flex">
                <div className="w-25">Signature</div>
                <div className="w-75 border-bottom">:</div>
              </div>
            </div>
            <div className="w-100 pb-md ">
              <div className="display-flex">
                <div className="w-25">Date</div>
                <div className="w-75 border-bottom">
                  : {initialData.date || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="txt-align-justify mt-md">
            <i>
              *The Representative must be authorized in writing by Distributor
              to execute the Agreement. Blue Ocean may request documentation
              evidencing this authority.
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueOceanAgreement;
