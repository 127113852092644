import { useState } from "react";

export const useInputHandlers = (initialValues: any) => {
  const [inputValues, setInputValues] = useState(initialValues);

  const [errors, setErrors] = useState({
    subscriberSignature: "",
    subscriberName: "",
    subscriberFirstName: "",
    subscriberLastName: "",
    subscriberTitle: "",
    subscriberTelephone: "",
    subscriberEmail: "",
    subscriberCopyTo: "",
    subscriberAddressOne: "",
    subscriberAddressTwo: "",
  });

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidTelephone = (phone: string) => {
    return /^\d{7,15}$/.test(phone);
  };

  // Handle input change for all fields
  const handleInputChange = (name: string, value: string) => {
    setInputValues({ ...inputValues, [name]: value });

    // Clear error if the user starts typing
    if (value.trim()) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  // Handle error event for validation
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Check if field is empty and set appropriate error message
    if (!value.trim()) {
      setErrors({ ...errors, [name]: "Please enter valid data" });
    } else if (
      (name === "subscriberEmail" || name === "subscriberCopyTo") &&
      !isValidEmail(value)
    ) {
      setErrors({ ...errors, [name]: "Please enter a valid email address" });
    } else if (name === "subscriberTelephone" && !isValidTelephone(value)) {
      setErrors({ ...errors, [name]: "Please enter a valid  telepone number" });
    }
  };

  return { inputValues, errors, handleInputChange, handleBlur };
};
