/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
 */
import { useEffect, useState } from "react";
import "../../../assets/scss/index.scss";
import "../../../assets/themes/theme1-color-variables.css";
import "../../../assets/themes/theme2-color-variables.css";
import { useInputHandlers } from "./CBOENonProAgreementValidations";

const CBOENonProAgreement = ({
  onChange,
  initialData,
  resData,
  setIsValidated,
  subsData,
}: any) => {
  console.log("Web Tech Data", subsData?.customerNumber);

  const initialValues = {
    subscriberName:
      subsData?.userInfo?.firstName && subsData?.userInfo?.lastName
        ? `${subsData.userInfo.firstName} ${subsData.userInfo.lastName}`
        : resData?.FirstName && resData?.LastName
        ? `${resData.FirstName} ${resData.LastName}`
        : "",
    subscriberEmail: subsData?.userInfo?.email
      ? subsData.userInfo.email
      : resData?.Email
      ? resData.Email
      : "",
    subscriberSignature: "",
  };

  const { inputValues, errors, handleInputChange, handleBlur } =
    useInputHandlers(initialValues);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const onCheckBoxChange = (checked: any) => {
    setAgreeCheck(checked);
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(inputValues).every(
      (value: any) => value.trim() !== ""
    );
    const noErrors = Object.values(errors).every((error) => error === "");
    const isFormValid = allFieldsFilled && noErrors && agreeCheck;

    setIsValidated(isFormValid);
  }, [inputValues, errors, agreeCheck]);

  return (
    <div className="txt-size-xs p-lg txt-color-body-shaded-2">
      <div>
        <div className="display-flex jc-center mt-sm txt-size-sm txt-weight-bold txt-align-center">
          Cboe Global Markets
        </div>
        <div className="display-flex jc-center mt-sm txt-size-md txt-weight-bold txt-align-center">
          Data Subscriber Agreement
        </div>
        <div className="mt-sm txt-size-sm txt-weight-bold">
          <p className="txt-align-center txt-align-justify">
            Vendor may not modify or waive any term of this Agreement. Any
            attempt to modify this Agreement, except by Cboe or its Affiliates,
            is void.
          </p>
        </div>
        <div>
          <p className="txt-align-justify">
            This Cboe Global Markets Data Subscriber Agreement (this
            “Agreement”), with an effective date as of the last date executed on
            the signature page hereof, is made by and between the vendor
            referenced below (“Vendor”) and the subscriber referenced below
            (“Subscriber”).
          </p>
        </div>
        {/* <!-- 01 --> */}
        <div>
          <p>
            <b className="txt-color-heading-2">1.Definitions. </b>Capitalized
            terms used herein shall have the meanings set forth in this Section
            1.
          </p>
          <div>
            <p className="txt-align-justify">
              <b>“Affiliate” </b>means with respect to an entity, another entity
              that, from time to time, directly or indirectly Controls, is
              Controlled by, or is under common Control with it.
            </p>
            <p className="txt-align-justify">
              <b>“Cboe” </b>means the applicable Cboe Contracting Entity
              specified in Section 26 of the Cboe Global Markets Global Data
              Agreement (“GDA”), as may be modified from time to time.
            </p>
            <p className="txt-align-justify">
              <b>“Cboe Global Markets” </b>means Cboe Global Markets, Inc. and
              any successor organization to Cboe Global Markets, Inc.
            </p>
            <p className="txt-align-justify">
              <b>“Cboe Indemnified Parties” </b>means, collectively, Cboe, its
              Affiliates and Third-Party Information Providers, and its and
              their respective owners, officers, directors, employees,
              contractors, and agents.
            </p>
            <p className="txt-align-justify">
              <b>“Cboe System” </b>means any system Cboe or its Affiliates have
              developed for creation and/or dissemination of Data.
            </p>
            <p className="txt-align-justify">
              <b>“Claims and Losses” </b>means any and all liabilities,
              obligations, losses, damages, penalties, claims, actions, suits,
              proceedings, costs, judgments, settlements, and expenses of any
              nature, whether incurred by or issued against an indemnified party
              or a third party, including, without limitation, (a) indirect,
              special, punitive, consequential, or incidental loss or damage,
              and (b) administrative costs, investigatory costs, litigation
              costs, and auditors’ and attorneys’ fees and expenses (including
              in-house personnel)
            </p>
            <p className="txt-align-justify">
              <b>“Control” </b>means the possession, direct or indirect, of the
              power to direct or cause the direction of the management and
              policies of another entity, whether through the ownership of
              voting securities, by contract, or otherwise.
            </p>
            <p className="txt-align-justify">
              <b>“Data” </b>means certain data and other information
              disseminated by a Cboe System (a) relating to securities or other
              financial instruments, products, vehicles, currencies, indices,
              values, indicators, markets or other means; (b) related to Persons
              regulated by an Exchange or to activities of an Exchange; or
              &#40;c&#41; made available by Cboe as Cboe may designate from time
              to time.
            </p>
            <p className="txt-align-justify">
              <b>“Data Policies” </b>” means such policies with respect to
              receipt, use and/or distribution of Data as Cboe or its Affiliates
              may adopt and make publicly available from time to time
            </p>
            <p className="txt-align-justify">
              <b>“Exchange” </b>” and <b>“Exchanges” </b>means, individually or
              collectively, any Affiliate of Cboe Global Markets that operates a
              national securities exchange, designated contract market,
              alternative trading system, multilateral trading facility,
              recognized exchange, regulated investment exchange, or other
              organized trading venue.
            </p>
            <p className="txt-align-justify">
              <b>“Non-Professional Subscriber” </b>means a Non-Professional Data
              User, Non-Professional User, or Non-Professional Customer, each as
              defined in the applicable Data Policies. Refer to Exhibit A
              attached hereto for definitions of these terms.
            </p>
            <p className="txt-align-justify">
              <b>“Person” </b>means any individual, corporation, limited
              liability company, trust, joint venture, association, company,
              limited or general partnership, unincorporated organization, or
              other entity.
            </p>
            <p className="txt-align-justify">
              <b>“Professional Subscriber” </b>means a Professional Data User,
              Retail-Professional Data User, Professional User or Professional
              Customer, each as defined in the applicable Data Policies. Refer
              to Exhibit A attached hereto for definitions of these terms
            </p>
            <p className="txt-align-justify">
              <b>“Regulatory Requirements” </b>means (a) the rules, regulations,
              interpretations, decisions, opinions, orders and other
              requirements of any applicable regulatory authority, such as the
              Securities and Exchange Commission and the Financial Industry
              Regulatory Authority (for U.S. activities) and the member
              jurisdictions of the Canadian Securities Administrators and the
              Investment Industry Regulatory Organization of Canada (for
              Canadian activities) and the Australian Securities and Investments
              Commission (for Australian activities); (b) the rules and
              regulations, disciplinary decisions and rule interpretations of
              the Exchanges; (c) the Exchanges’ decisions, policies (including
              without limitation the Data Policies), interpretations, user
              guides, operating procedures, specifications, requirements and
              other documentation that is regulatory or technical in nature
              published on Cboe Global Markets’ website or successor website;
              and (d) all other applicable laws, statutes, rules, regulations,
              orders, decisions, interpretations, opinions and other legal or
              regulatory requirements in effect in the respective national,
              state, provincial, local, or other relevant jurisdictions in which
              the Exchanges are located
            </p>
            <p className="txt-align-justify">
              <b>“Subscriber” </b>means, collectively, all Non-Professional
              Subscribers and Professional Subscribers.
            </p>
            <p className="txt-align-justify">
              <b>“Third-Party Information Providers” </b>means Persons that are
              not Affiliates of Cboe and that are the source and/or owner of
              Data distributed under this Agreement or are involved in any
              aspect of creating or providing Data distributed under this
              Agreement. For example, and not as a limitation, this definition
              includes Persons that are not Affiliates of Cboe that create, own,
              license and/or provide Data to Cboe (such as providers of indexes
              and other values), and Persons whose information or methodology is
              used to create Data distributed by Cboe (such as providers of
              input data used to calculate indexes or other values), and
              licensors of indexes used as the underlying for index options,
              futures or other derivative contracts for which Cboe distributes
              Data
            </p>
            <p className="txt-align-justify">
              <b>“Vendor” </b> means “Data Recipient,” as that term is defined
              in the GDA, as may be modified from time to time
            </p>
            <p className="txt-align-justify">
              <b>“Vendor’s Service” </b>means the service from a Vendor,
              including the data processing equipment, software, and
              communications facilities related thereto, for receiving,
              processing, transmitting, using, and disseminating Data to or by
              Subscriber.
            </p>
          </div>
        </div>
        {/* <!-- 02 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">2. Use of Data. </b>Subscriber
            may not sell, lease, furnish, or otherwise permit or provide access
            to Data to any other Person or to any other office or place.
            Subscriber will not engage in the operation of any illegal business
            use or permit anyone else to use Data, or any part thereof, for any
            illegal purpose or violation of any Regulatory Requirements.
            Subscriber may not present Data rendered in any unfair, misleading,
            or discriminatory format. Subscriber shall take reasonable security
            precautions to prevent unauthorized Persons from gaining access to
            Data. Subscriber shall not use, or permit any third party to use,
            any Data (i) as input data (e.g., as a constituent or signal) in the
            creation or calculation of any index or similar work or to create
            input data for use in the creation or calculation of any index or
            similar work, or (ii) to create any financial instrument, investment
            product, or investment strategy that is based on, or seeks to match
            the performance of, values included in the Data, except as
            authorized in writing by Cboe or a Cboe Affiliate or, with respect
            to any Data owned by a third party, the applicable Third-Party
            Information Provider.
          </p>
          <div>
            <p className="txt-align-justify">
              <b className="txt-color-heading-2">
                <u>(a) Use by Non-Professional Subscribers.</u>{" "}
              </b>
              Data is licensed only for personal, non-commercial use by a
              Non-Professional Subscriber. By representing to Vendor that
              Subscriber is a Non-Professional Subscriber, or by continuing to
              receive Data at a Non-Professional Subscriber rate, Subscriber is
              affirming to Vendor and Cboe that Subscriber meets the definition
              of Non-Professional Subscriber as set forth herein. A
              Non-Professional Subscriber shall comply promptly with any
              reasonable request from Cboe, or its designee, for information
              regarding the Non-Professional Subscriber’s receipt, processing,
              display, use, and redistribution of Data.
            </p>

            <p className="txt-align-justify">
              <b className="txt-color-heading-2">
                <u>(b) Use by Professional Subscribers.</u>{" "}
              </b>
              Data is licensed for internal business use and/or personal use by
              a Professional Subscriber. Professional Subscriber may furnish
              limited amounts of Data to customers in written advertisements,
              correspondence, or other literature or during voice telephonic
              conversations not entailing computerized voice, automated
              information inquiry systems, or similar technologies, so long as
              any such furnishing is not regular or systematic and occurs only
              on an ad hoc occasional and infrequent basis. Professional
              Subscriber shall make its premises available to Cboe, or its
              designee, for physical inspection of Vendor’s Service and of
              Professional Subscriber’s use of Data (including review of any
              records regarding use of or access to Data and the number and
              locations of all devices that receive Data), all at reasonable
              times and upon reasonable notice, to ensure compliance with this
              Agreement
            </p>
          </div>
        </div>
        {/* <!-- 03 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">3. Proprietary Data. </b>Cboe
            grants to Subscriber a non-exclusive, non-transferable license
            during the term of the Agreement to receive Data distributed to it
            by Vendor and, thereafter, to use such Data as permitted under the
            terms of this Agreement and applicable Regulatory Requirements.
            Subscriber acknowledges and agrees that Cboe and its Affiliates have
            proprietary rights to Data that (a) originates on or relates to
            trading on any of the Exchanges; (b) relates to activities that are
            regulated or operated by one or more of the Exchanges; (c) Cboe
            derives from Data that originates on or relates to any of the
            Exchanges; and (d) is a compilation of information and data that
            Cboe gathers from other sources pursuant to separate agreements with
            those sources. Subscriber further acknowledges and agrees that
            Cboe’s Third-Party Information Providers (i) have exclusive
            proprietary rights in their respective information and data, and
            (ii) may impose certain requirements on the use and distribution of
            their respective information and data or information derived from
            their information and/or data that may be more restrictive than the
            rights set forth herein, and accordingly Subscriber’s rights under
            this Agreement with respect to Data including or based on such
            third-party information and data is subject to any such requirements
            imposed by the subject Third-Party Information Provider from time to
            time, notwithstanding any terms and conditions of this Agreement to
            the contrary. In the event of any misappropriation or misuse by
            Subscriber or anyone who accesses Data through Subscriber, Cboe or
            its Affiliates or Third-Party Information Providers shall have the
            right to obtain injunctive relief for its respective materials.
            Subscriber shall attribute the source of Data as appropriate under
            all circumstances.
          </p>
        </div>
        {/* <!-- 04 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">4. Payment. </b>Subscriber shall
            assume full and complete responsibility for the payment of any
            taxes, charges, or assessments imposed on Subscriber or Cboe (except
            for taxes, if any, imposed on the net income of Cboe) by any foreign
            or domestic national, state, provincial, or local governmental
            bodies, or subdivisions thereof, and any penalties or interest
            relating to the provision of Data to Subscriber. Interest shall be
            due from the date of the invoice to the time that the amounts that
            are due have been paid. To the extent permitted by applicable law,
            Subscriber acknowledges and agrees that the termination of Vendor’s
            Service for failure to make payments shall not be considered an
            improper limitation of access by Cboe. For Professional Subscribers,
            if any payment is due directly to Cboe under this Agreement, payment
            in full is due Cboe in immediately available funds within 30 days of
            the date of an invoice, whether or not use is made of, or access it
            made to, Data. Subscriber agrees to pay Cboe any applicable late
            fees on all past due amounts that are not the subject of a
            legitimate and bona fide dispute.
          </p>
        </div>
        {/* <!-- 05 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">5. Cboe System. </b>Subscriber
            acknowledges that Cboe, in its sole discretion, may from time to
            time make modifications additions, and/or deletions to the Cboe
            System or Data or any aspect of either. Such modifications
            additions, or deletions may require corresponding changes to be made
            to Vendor’s Service. Changes or the failure to make timely changes
            by Vendor may sever, delay, or otherwise affect Subscriber’s access
            to or use of Data. Cboe shall not be responsible for any such
            effects. Cboe does not endorse or approve any Vendor, Vendor’s
            Service, or equipment utilized by Vendor or Subscriber
          </p>
        </div>
        {/* <!-- 06 --> */}
        <div>
          <b className="txt-color-heading-2">6. Limitation of Liability. </b>
          <br />
          <p className="txt-align-justify">
            To the maximum extent permitted by law, Cboe Indemnified Parties
            shall not be liable to Subscriber or to any other Person for any
            inaccurate or incomplete Data received from Cboe or from Vendor, any
            delays, interruptions, errors, or omissions in the furnishing
            thereof, or any direct, indirect, or consequential damages arising
            from or occasioned by said inaccuracies, delays, interruptions,
            errors, or omissions.
          </p>

          <p className="txt-align-justify">
            This Section shall not relieve Cboe, Vendor, Subscriber, or any
            other Person from liability for damages that result from their own
            gross negligence or willful tortious misconduct or from personal
            injury or wrongful death claims.
          </p>

          <p className="txt-align-justify">
            Cboe, Vendor, and Subscriber understand and agree that the terms of
            this Section reflect a reasonable allocation of risk and limitation
            of liability.
          </p>
        </div>
        {/* <!-- 07 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">7. Disclaimer of Warranties. </b>
            SUBSCRIBER EXPRESSLY ACKNOWLEDGES THAT Cboe INDEMNIFIED PARTIES DO
            NOT MAKE ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OR ANY
            WARRANTIES OF MERCHANTABILITY, QUALITY OR FITNESS FOR A PARTICULAR
            PURPOSE.
          </p>
        </div>
        {/* <!-- 08 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">
              8. Third-Party Information Providers’ Limitation of Liability.{" "}
            </b>
            Cboe’s Third-Party Information Providers shall have no liability for
            any damages, whether direct or indirect, whether lost profits,
            indirect, special, or consequential damages of Subscriber or any
            other Person seeking relief through Subscriber relating to the
            accuracy of or delays or omissions in any Data provided by Cboe’s
            Third-Party Information Providers, even if the Third-Party
            Information Providers have been advised of the possibility of such
            damages. In no event will the liability of the Third-Party
            Information Providers or their affiliates to Subscriber or any other
            Person seeking relief through Subscriber pursuant to any cause of
            action, whether in contract, tort, or otherwise, exceed the fee paid
            by Subscriber or any other Person seeking relief through Subscriber,
            as applicable.
          </p>
        </div>
        {/* <!-- 09 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">9. Claims and Losses. </b>
            Subscriber agrees to indemnify and hold harmless Cboe Indemnified
            Parties from any and all Claims and Losses imposed on, incurred by,
            or asserted as a result of or relating to: (a) any noncompliance by
            Subscriber with the terms and conditions hereof; or (b) any
            third-party actions related to Subscriber’s receipt and use of Data,
            whether authorized or unauthorized under this Agreement. Each party
            agrees to indemnify and hold harmless (and in every case, Cboe shall
            be permitted to solely defend and settle) another party (including
            Cboe) and their owners, subsidiaries, Affiliates, officers,
            directors, employees, agents, and any related Persons, against any
            Claims and Losses arising from, involving, or relating to a claim of
            infringement or other violation of an intellectual property right by
            the indemnifying party provided that: (a) the indemnified party
            promptly notifies the indemnifying party in writing of the Claims
            and Losses; and (b) the indemnified party reasonably cooperates in
            the defense of the Claims and Losses.
          </p>
        </div>
        {/* <!-- 10 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">10. Termination. </b>Subscriber
            acknowledges that Cboe, when required to do so in fulfillment of
            statutory obligations or otherwise, may by notice to Vendor
            unilaterally limit or terminate the right of any or all Persons to
            receive or use Data, or any part thereof, and that Vendor shall
            immediately comply with any such notice and terminate or limit the
            furnishing of Data and confirm such compliance by written notice to
            Cboe. Any affected Person will have available to it such procedural
            protections as are provided by applicable Regulatory Requirements.
            In addition to the termination rights permitted under any agreement
            Subscriber may have with Vendor, this Agreement may be terminated by
            Subscriber upon 30 days’ written notice to Vendor and by Cboe upon
            30 days’ written notice either to Vendor or Subscriber. In the event
            of Subscriber’s breach, the discovery of the untruth of any
            representation or warranty of Subscriber, or where directed by a
            regulatory authority having jurisdiction over Cboe or a Cboe
            Affiliate, Cboe may terminate this Agreement upon not less than 3
            days’ written notice to Subscriber provided either by Cboe or
            Vendor.
          </p>
        </div>
        {/* <!-- 11 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">11. Notices. </b>All
            communications required to be given in writing to Cboe under this
            Agreement shall be directed to the address or email address of Cboe
            set forth in the Data Policies. All communications required to be
            given in writing under this Agreement by Vendor to Subscriber or by
            Subscriber to Vendor shall be directed to the respective email
            address listed for the Vendor or Subscriber, as the case may be, on
            the signature page hereof. Direct communication by Cboe to
            Subscriber at the last address known to Vendor shall be considered
            given (a) upon actual receipt if delivered by email, or (b) upon
            posting the notice or other communication on Cboe Global Markets’
            website or successor website. Subscriber promptly shall give written
            notice to Vendor of any change in the name or place of residence or
            business at which Data is received.
          </p>
        </div>
        {/* <!-- 12 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">12. Assignment. </b>This
            Agreement shall inure to the benefit of and shall be binding upon
            the parties hereto and their respective permitted successors and
            assigns. Neither Vendor nor Subscriber shall assign this Agreement
            in whole or in part (including by operation of law) without the
            prior written consent of Cboe, provided, however, that Cboe shall
            not unreasonably withhold such consent. Notwithstanding the
            foregoing, Vendor or Subscriber may assign this Agreement in its
            entirety to an Affiliate or subsidiary without the prior written
            consent of Cboe, provided that the assigning party is not currently
            in breach of this Agreement or delinquent in any fees owed to Cboe.
            Cboe may assign or transfer this Agreement or any of its rights or
            obligations hereunder to a related or unrelated party upon notice to
            Vendor and Subscriber.
          </p>
        </div>
        {/* <!-- 13 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">13. Severability. </b>Each
            provision of this Agreement is intended to be severable. If any
            provision of this Agreement is or becomes illegal, invalid or
            unenforceable in any jurisdiction, such provision of this Agreement
            shall be severed and the illegality, invalidity or unenforceability
            of that provision will not affect: (i) the legality, validity or
            enforceability of the remaining provisions of this Agreement; or
            (ii) the legality, validity or enforceability of that provision in
            any other jurisdiction.
          </p>
        </div>
        {/* <!-- 14 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">
              14. Entire Agreement; Amendment; Waiver.{" "}
            </b>
            This Agreement constitutes the complete and entire agreement of the
            parties to this Agreement with respect to its subject matter and
            shall supersede all prior writings or understandings with respect
            thereto. If there is any conflict and/or inconsistency between this
            Agreement and Vendor’s agreement with Subscriber, the terms of this
            Agreement shall prevail as between Cboe and Subscriber.
            Notwithstanding anything to the contrary in Sections 9 and 15,
            nothing in this Agreement shall be construed to mean that either
            party is required to take any action, provide any defense, or
            subject itself to any jurisdiction, that is contrary to, or
            prohibited by, or otherwise in violation of any applicable laws or
            regulations. Cboe may modify any term of this Agreement upon 90
            days’ written notice either to Vendor or Subscriber, and any receipt
            or use of Data after such date shall be deemed acceptance of the new
            term or condition. The means of notifying Subscriber of such
            amendment may include, but not be limited to, emailing such term or
            condition to Vendor or posting such alteration on the Website upon
            written notice to Vendor.
          </p>
          <p className="txt-align-justify">
            Cboe may amend this Agreement without notice where the amendment is
            to introduce a new Data product.
          </p>
          <p className="txt-align-justify">
            No failure on the part of Cboe or Subscriber to exercise, no delay
            in exercising, and no course of dealing with respect to any right,
            power, or privilege under the Agreement shall operate as a waiver
            thereof, nor shall any single or partial exercise of any such right,
            power, or privilege preclude any other or further exercise thereof
            or the exercise of any other right, power, or privilege under this
            Agreement
          </p>
        </div>
        {/* <!-- 15 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">15. Governing Law; Venue. </b>The
            parties agree that the law that will apply in any dispute or lawsuit
            arising out of or in connection with this Agreement, and the courts
            that have jurisdiction over any such dispute or lawsuit, are
            determined as specified in Section 26 of the GDA.
          </p>
        </div>
        {/* <!-- 16 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">16. Headings. </b>Section
            headings are included for convenience only and are not to be used to
            construe or interpret this Agreement. All references contained
            herein to sections or subsections shall refer to the sections or
            subsections of this Agreement, unless specific reference is made to
            the sections or subsections of another document.
          </p>
        </div>
        {/* <!-- 17 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">17. Third-Party Beneficiary. </b>
            Vendor and Subscriber hereby designate Cboe and Third-Party
            Information Providers as third-party beneficiaries of this
            Agreement, having the right to enforce any provision herein.
          </p>
        </div>
        {/* <!-- 18 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">18. Cumulative Remedies. </b>
            Except as otherwise limited herein, all rights and remedies provided
            in this Agreement are cumulative and not exclusive, and the exercise
            by either party of any right or remedy does not preclude the
            exercise of any other rights or remedies that may now or
            subsequently be available at law, equity, by statute, in any other
            agreement between the parties or otherwise.
          </p>
        </div>
        {/* <!-- 19 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">19. Counterparts. </b>This
            Agreement may be executed in one or more counterparts, which shall
            each be considered an original but all of which shall constitute one
            and the same Agreement.
          </p>
        </div>
        {/* <!-- 20 --> */}
        <div>
          <p className="txt-align-justify">
            <b className="txt-color-heading-2">20. Language. </b>The parties
            confirm that it is their wish that this Agreement be written in the
            English language only.
          </p>
        </div>
        {/* <!-- form --> */}
        <div className="">
          <div className="">
            <p className="txt-align-justify">
              <b>
                To execute this Agreement, you must be 18 years of age and you
                must designate yourself as either a Non-Professional Subscriber
                or Professional Subscriber (see Section 2 above).
              </b>
            </p>
          </div>
          <div className="display-flex">
            <div className="w-25">
              <b>Subscriber Type:</b>
            </div>

            <div className="display-flex">
              <input
                type="checkbox"
                id="individualCheckbox"
                value="individual"
                name="subscriber_type"
                checked={agreeCheck}
                onChange={(event) => {
                  onChange(event.target.name, event.target.value);
                  onCheckBoxChange(event.target.checked);
                }}
              />
              <label className="ml-sm">Individual – Complete Section A.</label>
            </div>
          </div>

          <div className="mt-md ml-md">
            <div>
              <b>A. Individual Subscriber Information</b>
            </div>
            <div>
              <div className="mt-sm display-flex jc-center">
                <label className="w-25">Subscriber Name:</label>
                <div className="w-75">
                  <input
                    type="text"
                    className={`p-sm txt-size-xs bg-color-paper-1 col-12 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                    ${
                                      errors.subscriberName
                                        ? "b-xs-shaded-4"
                                        : ""
                                    }`}
                    id="subscriberName"
                    placeholder="Enter subscriber name"
                    name="subscriberName"
                    value={inputValues.subscriberName}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      onChange(event.target.name, event.target.value);
                      handleInputChange(event.target.name, event.target.value);
                    }}
                  />
                  {errors.subscriberName && (
                    <span style={{ color: "red" }}>
                      {errors.subscriberName}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-sm display-flex jc-center">
                <label className="w-25">Email:</label>
                <div className="w-75">
                  <input
                    type="email"
                    className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2
                                     ${
                                       errors.subscriberEmail
                                         ? "b-xs-shaded-4"
                                         : ""
                                     }`}
                    id="email"
                    placeholder="Enter email"
                    name="subscriberEmail"
                    value={inputValues.subscriberEmail}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      onChange(event.target.name, event.target.value);
                      handleInputChange(event.target.name, event.target.value);
                    }}
                  />
                  {errors.subscriberEmail && (
                    <span style={{ color: "red" }}>
                      {errors.subscriberEmail}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-sm display-flex jc-center">
                <label className="w-25" htmlFor="date">
                  Date:
                </label>
                <div className="w-75">
                  <input
                    className="p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 "
                    placeholder="Date"
                    type="text"
                    name="date"
                    value={initialData.date || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-sm display-flex jc-center">
                <label className="w-25">Signature:</label>
                <div className="w-75">
                  <input
                    type="text"
                    className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                    ${
                                      errors.subscriberSignature
                                        ? "b-xs-shaded-4"
                                        : ""
                                    }`}
                    id="signature"
                    placeholder="Enter signature"
                    name="subscriberSignature"
                    value={inputValues.subscriberSignature}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      onChange(event.target.name, event.target.value);
                      handleInputChange(event.target.name, event.target.value);
                    }}
                  />
                  {errors.subscriberSignature && (
                    <span style={{ color: "red" }}>
                      {errors.subscriberSignature}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="txt-weight-bold">
              <u>Subscriber Status:</u>
            </p>
          </div>
          <div className="display-flex">
            <div className="w-75">
              <b>North American Data:</b>
            </div>
            <div className="display-flex w-75">
              <input type="checkbox" defaultChecked disabled />
              <label className="ml-sm">Non-Professional*</label>
            </div>
          </div>
          <div className="display-flex">
            <div className="w-75">
              <b>European Equities & Derivatives:</b>
            </div>
            <div className="display-flex w-75">
              <input type="checkbox" defaultChecked disabled />
              <label className="ml-sm">Non-Professional*</label>
            </div>
          </div>
          <div className="display-flex">
            <div className="w-75">
              <b>European Indices:</b>
            </div>
            <div className="display-flex w-75">
              <input type="checkbox" defaultChecked disabled />
              <label className="ml-sm">Non-Professional*</label>
            </div>
          </div>
          <p className="mt-sm">
            <i>
              *To qualify as a Non-Professional Subscriber, you must meet all of
              the terms set forth in Section 2 of the Agreement.
            </i>
          </p>
        </div>
      </div>
      <div className="ml-sm">
        <div className="txt-size-sm txt-weight-bold">
          Vendor Information (for Vendor or Data Provider Use Only)
        </div>

        {/* Vendor Section */}
        <div className="mt-sm">
          <div className="display-flex">
            <div className="w-50">Vendor Name</div>
            <div className="w-75 border-bottom">: GTN Group Holding Ltd</div>
          </div>
          <div className="display-flex mt-sm">
            <div className="w-50">Representative Name</div>
            <div className="w-75 border-bottom">:</div>
          </div>

          <div className="display-flex mt-sm">
            <div className="w-50">Title</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          <div className="display-flex mt-sm">
            <div className="w-50">Email</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          <div className="display-flex mt-sm">
            <div className="w-50">Signature</div>
            <div className="w-75 border-bottom">:</div>
          </div>
          {/* Date Section */}

          <div className="display-flex mt-sm">
            <div className="w-50">Date</div>
            <div className="w-75 border-bottom">: {initialData.date || ""}</div>
          </div>
          <p className="mt-sm">
            <i>
              *The Representative must be authorized in writing by Vendor to
              execute the Agreement. Cboe may request documentation evidencing
              this authority.
            </i>
          </p>
          <hr></hr>
        </div>
      </div>
      <div className="txt-weight-bold txt-size-sm  display-flex jc-center">
        <p>
          <u>EXHIBIT A</u>
        </p>
      </div>
      <div>
        <table className="b-sm-shaded-3 txt-align-center">
          <thead>
            <tr>
              <th className="b-sm-shaded-1 p-md">Product Category</th>
              <th className="b-sm-shaded-1 p-md">Data Policies</th>
              <th className="b-sm-shaded-1 p-md">
                Non-Professional and Professional Definition
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="b-sm-shaded-1 p-md">
                <p className="txt-size-xs">
                  &#8226;U.S. equities, options, futures, and indices
                </p>
                <p className="txt-size-xs mt-md">
                  &#8226;Foreign exchange (FX)
                </p>
                <p className="txt-size-xs mt-md">
                  &#8226;Digital asset spot and derivatives
                </p>
                <p className="txt-size-xs mt-md">
                  &#8226;Canadian equities –MATCHNow, Cboe One Canada Feed
                </p>
                <p className="txt-size-xs mt-md">
                  &#8226;Canadian equities –Neo Exchange Inc.
                </p>
              </td>
              <td className="b-sm-shaded-1">North American Data Policies</td>
              <td className="b-sm-shaded-1">
                <table className="txt-align-justify">
                  <tbody>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md">North American Data Policies</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        Non-Professional User is a natural person or qualifying
                        trust that uses Data only for personal purposes and not
                        for any commercial purpose and, for a natural person who
                        works in the United States, is not: (i) registered or
                        qualified in any capacity with the Securities and
                        Exchange Commission, the Commodities Futures Trading
                        Commission, any state securities agency, any securities
                        exchange or association, or any commodities or futures
                        contract market or association; (ii) engaged as an
                        “investment adviser” as that term is defined in Section
                        202(a)(11) of the Investment Advisers Act of 1940
                        (whether or not registered or qualified under that Act);
                        or (iii) employed by a bank or other organization exempt
                        from registration under federal or state securities laws
                        to perform functions that would require registration or
                        qualification if such functions were performed for an
                        organization not so exempt; or, for a natural person who
                        works outside of the United States, does not perform the
                        same functions as would disqualify such person as a
                        NonProfessional User if he or she worked in the United
                        States.
                      </td>
                    </tr>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md">Professional Data User</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        Any Data User other than a Non-Professional User
                      </td>
                    </tr>
                    <tr>
                      <th className="p-md">Retail-Professional Data User</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        A Retail-Professional Data User is a Professional Data
                        User whose primary business focus is providing financial
                        and securities trading advice to retail clients
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="b-sm-shaded-1">
              <td className="b-sm-shaded-1">
                European equities and derivatives
              </td>
              <td className="b-l-sm-shaded-1">
                European Equities & Derivatives Market Data Policy
              </td>
              <td className="b-l-sm-shaded-1">
                <table className="txt-align-justify">
                  <tbody>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md b-r-sm-shaded-1">
                        Non Professional Customer
                      </th>
                      <td className="b-r-sm-shaded-1 p-md">
                        “Non Professional Customer” is a customer who does not
                        meet the definition of a Professional Customer.
                      </td>
                    </tr>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md">Non Professional User</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        "Non Professional User” is a Data User of a Non
                        Professional Customer.
                      </td>
                    </tr>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md b-b-sm-shaded-1">
                        Professional Customer
                      </th>
                      <td className="b-l-sm-shaded-1 p-md">
                        “Professional Customer” means a customer who uses Market
                        Data to carry out a regulated financial service or
                        regulated financial activity or to provide a service for
                        third parties, or who is considered to be a large
                        undertaking, i.e. meeting two of the following size
                        requirements on a company basis: (i) balance sheet total
                        of EUR 20 000 000 (ii) net turnover of EUR 40 000 000
                        (iii) own funds of EUR 2 000 000.
                      </td>
                    </tr>
                    <tr>
                      <th className="p-md">Professional User</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        “Professional User” means a Data User of a Professional
                        Customer.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="b-sm-shaded-1">European indices</td>
              <td className="b-sm-shaded-1">Cboe Europe Index Data Policy</td>
              <td className="b-sm-shaded-1">
                <table className="txt-align-justify">
                  <tbody>
                    <tr className="b-b-sm-shaded-1">
                      <th className="p-md b-b-sm-shaded-1">
                        Non Professional User
                      </th>
                      <td className="b-l-sm-shaded-1 p-md">
                        “Non Professional User” is an individual who views or
                        uses the Information in a personal capacity for his/her
                        own personal investment activities, and not as a
                        principal, officer, partner, employee, contractor or
                        agent of any business, or on behalf of any other
                        individual or business.
                      </td>
                    </tr>
                    <tr>
                      <th className="p-md">Professional User</th>
                      <td className="b-l-sm-shaded-1 p-md">
                        “Professional User” is an individual, company or other
                        body corporate who is not a Non Professional User.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CBOENonProAgreement;
